// 腾讯智聆语音评测组件
// SOE API文档：https://cloud.tencent.com/document/product/884/19310
// CryptoJS加密算法：https://libraries.io/npm/crypto-js
// import sHA256 from 'crypto-js/sha256'
// import hmacSHA256 from 'crypto-js/hmac-sha512'
import CryptoJS from "crypto-js";
import { ToastManager } from "@/components/utils/Toast";

import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";

// + jQuery

// 生成uuid
function uuid() {
  let bytes = window.crypto.getRandomValues(new Uint8Array(32));
  const randomBytes = () => (bytes = bytes.slice(1)) && bytes[0];

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (randomBytes() & (15 >> (c / 4)))).toString(16)
  );
}

// 获取临时token
function getAuthorization(callback) {
  $.get("/soe/tmpToken", function (data) {
    callback(data.Credentials);
  });
}

// 加密方法
function encrypt(key, msg, type) {
  var hash;
  if (!key) {
    hash = CryptoJS.SHA256(msg);
  } else {
    // CryptoJS.HmacSHA256(msg, key);
    hash = CryptoJS.HmacSHA256(msg, key);
  }
  return hash;
}

function postAudioData(secret_id, secret_key, token, text, sessionId, seqId, isEnd, data) {
  return new Promise((resolve, reject) => {
    const api_host = "soe.tencentcloudapi.com";
    const timestamp = parseInt(new Date().getTime() / 1000);
    const day = new Date(new Date().getTime() - 8 * 60 * 60 * 1000);
    const date =
      day.getFullYear() +
      "-" +
      ("0" + (day.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + day.getDate()).slice(-2);

    // 音频数据
    var params = {
      SeqId: seqId,
      IsEnd: isEnd,
      VoiceFileType: 3,
      VoiceEncodeType: 1,
      UserVoiceData: data,
      SessionId: sessionId,
      RefText: text,
      WorkMode: 1,
      EvalMode: text.length > 1 ? (text.length > 20 ? 2 : 1) : 0,
      ScoreCoeff: 1.5,
      ServerType: 1,
      SentenceInfoEnabled: 1,
    };
    console.log("音频数据", params);
    // posts header 参数
    // 格式拼凑：https://console.cloud.tencent.com/api/explorer?Product=soe&Version=2018-07-24&Action=TransmitOralProcessWithInit&SignVersion=
    // 签名串生成 - 进行调试

    var headers = {};
    // headers["Host"] = api_host
    headers["Content-Type"] = "application/json";
    headers["X-TC-Action"] = "TransmitOralProcessWithInit";
    headers["X-TC-Region"] = ""; // ap-shanghai，留空就近分配
    headers["X-TC-Timestamp"] = String(timestamp);
    headers["X-TC-Version"] = "2018-07-24";
    headers["X-TC-Token"] = token;

    // 拼接规范请求串
    var body = JSON.stringify(params);
    var signed_headers = "content-type;host";
    var canonical_headers =
      "content-type:application/json\nhost:" + api_host + "\n";
    var hashed_request_payload = encrypt("", body, "hex");
    var canonical_request =
      "POST\n/\n\n" +
      canonical_headers +
      "\n" +
      signed_headers +
      "\n" +
      hashed_request_payload;

    // 拼接待签名字符串
    var string_to_sign =
      "TC3-HMAC-SHA256\n" +
      headers["X-TC-Timestamp"] +
      "\n" +
      date +
      "/soe/tc3_request\n" +
      encrypt("", canonical_request, "hex");
    var secret_date = encrypt("TC3" + secret_key, date, "binary");
    var secret_service = encrypt(secret_date, 'soe', "binary");
    var secret_signing = encrypt(secret_service, "tc3_request", "binary");

    // 计算签名
    var signature = encrypt(secret_signing, string_to_sign, "hex");
    // 拼接 Authorization
    headers["Authorization"] =
      "TC3-HMAC-SHA256 Credential=" +
      secret_id +
      "/" +
      date +
      "/soe/tc3_request, SignedHeaders=" +
      signed_headers +
      ", Signature=" +
      signature;
    // console.log('headers', headers);

    // 发送评测请求
    XinYaNativeBridge.request({
      url: "https://soe.tencentcloudapi.com",
      method: "POST",
      headers: headers,
      body: JSON.stringify(params)
    }).then(res => {
      console.log(res);
      resolve({ success: true, data: res.data });
    }).catch(() => reject({ success: false, error: '' }));
  });
}

// 音频分析
function SOE(data, text, callback) {
  // 获取临时授权
  getAuthorization(async function (tokens) {
    const toast = ToastManager.showLoading('语音上传中...');
    const limit = 512 * 1024;
    const sessionId = uuid();
    const len = data.length;
    let i = 0;
    let seqId = 1;
    let isEnd = 0;
    while (!isEnd) {
      const slice = data.substring(i, i + limit);
      i += limit;
      isEnd = i >= len ? 1 : 0;
      const res = await postAudioData(
        tokens.TmpSecretId,
        tokens.TmpSecretKey,
        tokens.Token,
        text,
        sessionId,
        seqId++,
        isEnd,
        slice
      );
      if (!res.success) {
        console.log("Failed to post audio data", res.error);
        toast.cancel();
        ToastManager.showToastError('音频上传失败');
        callback();
        break;
      }
      if (isEnd) {
        toast.cancel();
        callback(res.data);
      }
    }
  });
}

function SoeLog(target_type, target_id, text, response) {
  let {
    Response: { Status, SuggestedScore },
  } = response;

  console.log("response", response);

  let params = {
    soe: {
      target_type,
      target_id,
      audio_text: text,
      suggested_score: SuggestedScore,
      result: response,
    },
  };
  console.log("soe log", params);
  return new Promise((resolve, reject) => {
    $.ajax({
      url: "/soe/soe_log",
      type: "POST",
      dataType: "json",
      data: params,
      success: function (data, textStatus, xhr) {
        resolve(data);
      },
      error: function (xhr, textStatus, errorThrown) {
        reject(errorThrown);
      },
    });
  });
}

export { SOE, SoeLog, uuid, encrypt };
