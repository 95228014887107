import React from "react";
import mediumPlaybackRateController from "./MediumPlaybackRateController";
import Hls from "hls.js";

class WrappedMedium extends React.Component {
  componentDidMount() {
    this.initializeHls();
    mediumPlaybackRateController.register(this);
  }

  componentWillUnmount() {

    mediumPlaybackRateController.unregister(this);
  }

  initializeHls() {
    if(Hls.isSupported()) {
      const video = document.getElementById('lesson-video');
      const hls = new Hls();
      hls.loadSource(this.props.src);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        // video.play();
    });
   } else {
     console.log('不支持m3u8')
   }
  }

  onPlay = () => {
    if (this.props.playbackRateEnabled) {
      this.audioEl.playbackRate = mediumPlaybackRateController.getPlaybackRate();
    }
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  render() {
    const { innerRef, onPlay, playbackRateEnabled, ...props } = this.props;
    if (props.resourcetype === 'audio') {
      return (
        <audio
          ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
          { ...props }
          onPlay={this.onPlay}
        >
        </audio>
      );
    } else if (props.resourcetype === 'video') {
      return (
        <video
          ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
          { ...props }
          onPlay={this.onPlay}
          width="100%"
          id="lesson-video"
          poster={`${this.props.src}?vframe/jpg/offset/0`}
        >
        </video>
      );
    }
    return null;
  }
}

export default React.forwardRef((props, ref) => (
  <WrappedMedium {...props} innerRef={ref} />
));
