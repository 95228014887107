import React, { useMemo } from "react";
import qrcode from "media_lesson_activity/app_download.png";
import "./DownAppTip.scss";
export default (props) => {
  const DownAppTip = useMemo(() => {
    return (
      <div className="guide_wechat_follow">
        <div className="dialog_qrcode_body">
          <div className="btn-close-wechat" onClick={props.onPoetryDialog}>
            &times;
          </div>
          {props.is_show_qrcode ? <img src={qrcode} /> : ""}
          <p>{props.support_recorder_tips}</p>
        </div>
      </div>
    );
  });
  return DownAppTip;
};

// export default DownAppTip;
