import React, { Component } from "react";
import ReactDOM from "react-dom";
import AwesomeKeyboard from "./AwesomeKeyboard";
/**
  usage:
        const MediaLessonHasKeyboard = HasAwesomeKeyboard(MediaLessonItem,{ hasAwesomeKeyboard:                                                       this.state.hasAwesomeKeyboard, forcesToLeft: true })
        return <MediaLessonHasKeyboard {...this.props} />

      the passed arguments are a component and  a object in which the component entry is forced and the default entries of hasAwesomeKeyboard and forcesToLeft is true and false correspondingly
  caution:
      if the forcesToLeft element is effective, the final component (after wrapped) is based on relative/absolute layout, make should you passed component fits it good 
      if the forcesToLeft element is false, the wrapped component with such keyboard fits the change of orientation well, becs it based on flex layout
**/
export const HasAwesomeKeyboard = (PassedComponent, opts) => {
  if (!PassedComponent) {
    throw new Error("must pass a real component for HOC(HasAwesomeKeyboard)");
    return;
  }
  // default setting
  opts = Object.assign(
    {},
    { hasAwesomeKeyboard: true, forcesToLeft: false },
    opts
  );
  return class extends React.Component {
    render() {
      if (opts.hasAwesomeKeyboard) {
        return (
          <AwesomeKeyboard
            forcesToLeft={opts.forcesToLeft}
            render={(input, trigger, isShow) => (
              <PassedComponent
                {...this.props}
                awesome_keyboard_input={input}
                awesome_keyboard_trigger={trigger}
                key="component"
                isShowKeyboard={isShow}
              />
            )}
          />
        );
      } else {
        return <PassedComponent {...this.props} />;
      }
    }
  };
};
