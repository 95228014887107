import React from "react";
import { print } from "../../utils/helpers";
import CountDownTime from "../../utils/CountDown";
import count_time_icon from "count_down_course/count_time_icon.png";
import ready_go_back from "count_down_course/ready_go_back.png";
import ready_go from "count_down_course/ready_go.gif";
import { request } from "../../utils/request";
import Raven from "raven-js";
export default class MediaLessonQuizOption extends React.Component {
  constructor(props) {
    super(props);
    console.log("MediaLessonQuizOption-props", props);
    let multiples = 0;
    const selectOptions =
      props.options &&
      props.options.map((option) => {
        if (option.correct) {
          multiples++;
        }
        return { id: option.id, className: "", option: option };
      });

    // print(props)

    const record = {
      start_answer_time: null,
      end_answer_time: null,
      total_answer_count: 0,
    };

    this.state = {
      isNoScore: this.props.isNoScore,
      multiples,
      again: false,
      isConfirm: multiples == 1 ? true : false,
      selected: 0,
      isDisplay: "none",
      isShowHide: "none",
      selectOptions: selectOptions,
      timeOutID: null,
      record: record,
      showEndtime: 0,
      effectSoundURL: "",
      liked: true,
      showMuilResult: false,
      nextLessRecord: null,
      race_quizzes_result: null,
      hideReady: this.props.currentQuiz.is_first_multiple_race_quiz
        ? false
        : true, //多题回答
      isMuilFirst: this.props.currentQuiz.is_first_multiple_race_quiz
        ? true
        : false, //多题回答
      limitTimes: this.props.currentQuiz.timeout_value
        ? this.props.currentQuiz.timeout_value
        : 0, //限时
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentWillMount() {
    if (this.state.timeOutID) {
      clearTimeout(this.state.timeOutID);
    }
  }
  setOptions = (newOptions) => {
    const record = {
      start_answer_time: null,
      end_answer_time: null,
      total_answer_count: 0,
    };
    const selectOptions = newOptions.map((option) => {
      return { id: option.id, className: "", option: option };
    });
    if (selectOptions && selectOptions.length > 0) {
      this.setState({
        selectOptions: selectOptions,
        selected: 0,
        record: record,
      });
    }
  };
  //获取该组件的倒计时结束时间
  changeTime(time) {
    this.setState({ showEndtime: time });
  }
  handleSelect(e) {
    console.log(this.answered, "answered--选择题this", this);
    if (!e) {
      // this.answered = true;
      let record = this.state.record;
      console.log("=============超时答题 请求空答案");
      this.props.operateSingleAnswerText.pushSingleAnswer("");

      return new Promise((resolve) => {
        const timeOutID = setTimeout(() => {
          // this.delayExcute.call(this);
          resolve();
        }, 500);
        record["end_answer_time"] = new Date();
        this.setState({
          timeOutID: timeOutID,
          record: record,
          selectOptions: [],
        });
        record["total_answer_count"] += 1;
        this.props
          .summitRecord(this.state.record)
          .then((res) => {
            console.log("-----222222222222222222222---res", res);
            this.answered = false;
            if (res.data.race_quizzes_result) {
              console.log(
                "000000000000000000000000000000000",
                res.data.race_quizzes_result
              );
              this.setState({
                race_quizzes_result: res.data.race_quizzes_result,
                nextLessRecord: res.data.lesson_record,
                showMuilResult: true,
              });
            } else {
              this.props.setLessonRecord(res.data.lesson_record);
              this.delayExcute();
            }
          })
          .catch((e) => {
            this.answered = false;
            this.setState({
              liked: true,
            });

            console.log(e, " summitQuizRecord(quizRecord, answerText) {");
            Raven.captureException(JSON.stringify(e));

            alert('请求错误，请稍后再试');
          });
        print("----record-one_time-fail----");
      })
    } else {
      e.preventDefault();
      const el = e.target;
      if (el.nodeName === "LI") {
        if (this.state.liked == false || this.answered) {
          return;
        }
        if (this.state.multiples == 1) {
          this.setState({
            liked: false,
          });
          this.answered = true;
        }
        print("选择了：选择题", this.props);
        let { isNoScore } = this.props;
        let index = parseInt(el.getAttribute("data-index"));
        let selectOption = this.state.selectOptions[index];
        let selected = this.state.selected;
        const newSelectOptions = this.state.selectOptions.map((element) => {
          if (element.id === selectOption.id) {
            if (element.className) {
              selected--;
            } else {
              selected++;
            }
          }
          let className =
            element.id === selectOption.id
              ? this.state.multiples > 1 && !this.state.isConfirm
                ? element.className
                  ? ""
                  : "selected"
                : element.option.correct
                ? "correct"
                : "error"
              : element.className;
          if (
            // element.id != selectOption.id ||
            isNoScore &&
            this.state.multiples <= 1
          ) {
            return { id: element.id, className: "", option: element.option };
          } else {
            return {
              id: element.id,
              className: className,
              option: element.option,
            };
          }
        });
        this.setState({
          selectOptions: newSelectOptions,
          selected,
        });
        if (!this.state.isConfirm && this.state.multiples > 1) {
          return;
        }
        print(newSelectOptions);
        this.props.pauseReplay();
        let record = this.state.record;
        let pre_answer_count = record.total_answer_count;
        record["total_answer_count"] = pre_answer_count + 1;
        print("this.props.try_round 需要回答次数:" + this.props.try_round);
        print("record['total_answer_count']", record["total_answer_count"]);
        if (record["total_answer_count"] < this.props.try_round) {
          if (selectOption.option.correct) {
            // return Promise.resolve().then(() => {
            //   let audio = document.getElementById('effect_pass_sound')
            //   audio.play()
            // })
            print("isNoScore", isNoScore);
            record["end_answer_time"] = new Date();
            this.props
              .promisifiedPlayEffectAudioWithReward(
                isNoScore ? "effect_noscore_sound" : "effect_pass_sound",
                this.state.record
              )
              .then((res) => {
                return new Promise((resolve) => {
                  const timeOutID = setTimeout(() => {
                    // this.delayExcute.call(this);
                    resolve();
                  }, 500);
                  print(selectOption.option.content);
                  this.props.operateSingleAnswerText.pushSingleAnswer(
                    selectOption.option.content
                  );
                  this.setState({
                    timeOutID: timeOutID,
                    record: record,
                    // selectOptions: newSelectOptions,
                  });

                  this.props
                    .summitRecord(this.state.record)
                    .then((res) => {
                      console.log(
                        this,
                        "-----222222222222222222222---res",
                        res
                      );
                      this.answered = false;
                      if (res.data.race_quizzes_result) {
                        this.setState({
                          race_quizzes_result: res.data.race_quizzes_result,
                          nextLessRecord: res.data.lesson_record,
                          showMuilResult: true,
                        });
                      } else {
                        this.props.setLessonRecord(res.data.lesson_record);
                        this.delayExcute();
                      }
                    })
                    .catch((e) => {
                      this.answered = false;
                      this.setState({
                        liked: true,
                      });

                      console.log(
                        e,
                        " summitQuizRecord(quizRecord, answerText) {"
                      );
                      Raven.captureException(JSON.stringify(e));

                      alert('请求错误，请稍后再试');
                    });
                  print("----record-success----");
                });
              })
          } else {
            this.props
              .promisifiedPlayEffectAudioWithReward(
                isNoScore ? "effect_noscore_sound" : "effect_fail_human_sound"
              )
              .then((res) => {
                this.answered = false;
                print(selectOption.option.content);
                this.props.operateSingleAnswerText.pushSingleAnswer(
                  selectOption.option.content
                );
                return new Promise((resolve) => {
                  this.setState({
                    record: record,
                    // selectOptions: newSelectOptions,
                  });
                  (function () {
                    const newSelectOptions = this.state.selectOptions.map(
                      (element) => {
                        return {
                          id: element.id,
                          className: "",
                          option: element.option,
                        };
                      }
                    );
                    this.setState({
                      selectOptions: newSelectOptions,
                      selected: 0,
                    });
                    resolve();
                  }.bind(this)());
                  print("----record-time_after_time_fail_human-----");
                });
              })
              .then(() => {
                this.setState({
                  liked: true,
                });
              })
              .catch((err) => {
                // print(err)
                console.log("----record-time_after_time_fail_human-----", err);
                Raven.captureException(JSON.stringify(err));
              });
          }
        } else if (record["total_answer_count"] == this.props.try_round) {
          if (selectOption.option.correct) {
            print("isNoScore", isNoScore);
            record["end_answer_time"] = new Date();
            this.props
              .promisifiedPlayEffectAudioWithReward(
                isNoScore ? "effect_noscore_sound" : "effect_pass_sound",
                this.state.record
              )
              .then((res) => {
                console.log(selectOption.option.content);
                this.props.operateSingleAnswerText.pushSingleAnswer(
                  selectOption.option.content
                );
                return new Promise((resolve) => {
                  const timeOutID = setTimeout(() => {
                    // this.delayExcute.call(this);
                    resolve();
                  }, 500);

                  this.setState({
                    timeOutID: timeOutID,
                    record: record,
                    // selectOptions: newSelectOptions,
                  });
                  this.props
                    .summitRecord(this.state.record)
                    .then((res) => {
                      console.log(
                        this,
                        "-----222222222222222222222---res",
                        res.data.race_quizzes_result
                      );
                      this.answered = false;
                      if (res.data.race_quizzes_result) {
                        console.log("000000000000000000000000000000000");
                        this.setState({
                          race_quizzes_result: res.data.race_quizzes_result,
                          nextLessRecord: res.data.lesson_record,
                          showMuilResult: true,
                        });
                      } else {
                        this.props.setLessonRecord(res.data.lesson_record);
                        this.delayExcute();
                      }
                    })
                    .catch((e) => {
                      this.answered = false;
                      this.setState({
                        liked: true,
                      });

                      console.log(
                        e,
                        " summitQuizRecord(quizRecord, answerText) {"
                      );
                      Raven.captureException(JSON.stringify(e));
                      alert('请求错误，请稍后再试');
                    });
                  print("----record-one_time-success----");
                });
              })
              .then(() => {
                this.setState({
                  liked: true,
                });
              })
              .catch((err) => {
                // print(err)
                console.log("----record-one_time-success----22222222", err);
                Raven.captureException(JSON.stringify(err));
              });
          } else {
            print(selectOption.option.content);
            this.props.operateSingleAnswerText.pushSingleAnswer(
              selectOption.option.content
            );
            record["end_answer_time"] = new Date();
            record["total_answer_count"] += 1;
            this.props
              .promisifiedPlayEffectAudioWithReward(
                isNoScore ? "effect_noscore_sound" : "effect_fail_sound",
                this.state.record
              )
              .then((res) => {
                return new Promise((resolve) => {
                  const timeOutID = setTimeout(() => {
                    // this.delayExcute.call(this);
                    resolve();
                  }, 500);

                  this.setState({
                    timeOutID: timeOutID,
                    record: record,
                    // selectOptions: newSelectOptions,
                  });

                  this.props
                    .summitRecord(this.state.record)
                    .then((res) => {
                      console.log("-----222222222222222222222---res", res);
                      this.answered = false;
                      if (res.data.race_quizzes_result) {
                        this.setState({
                          race_quizzes_result: res.data.race_quizzes_result,
                          nextLessRecord: res.data.lesson_record,
                          showMuilResult: true,
                          // showEndtime: new Date().getTime() + 6 * 1000,
                        });
                      } else {
                        this.props.setLessonRecord(res.data.lesson_record);
                        this.delayExcute();
                      }
                    })
                    .catch((e) => {
                      this.answered = false;
                      this.setState({
                        liked: true,
                      });

                      console.log(
                        e,
                        " summitQuizRecord(quizRecord, answerText) {"
                      );
                      Raven.captureException(JSON.stringify(e));
                      alert('请求错误，请稍后再试');
                    });
                  // .catch((error) => {
                  //   console.log(error, " summitQuizRecord(quizRecord, answerText) {");
                  //   Raven.captureException(JSON.stringify(error));
                  // })
                  print("----record-one_time-fail----");
                });
              })
              .then(() => {
                this.setState({
                  liked: true,
                });
              })
              .catch((err) => {
                // print(err)
                console.log("----record-one_time-fail----22222222", err);
                Raven.captureException(JSON.stringify(err));
              });
          }
        }
      }
    }
  }

  //多选题确认按钮
  handleMultiplesConfirm() {
    const { selectOptions } = this.state;
    let { isNoScore } = this.props;
    if (!this.state.liked || this.answered) {
      return;
    }
    this.answered = true;

    this.setState({
      liked: false,
    });
    let correctAll = true;
    let selected = 0;
    let newSelectOptions = this.state.selectOptions.map((element) => {
      if (!element.option.correct && element.className) {
        selected++;
        correctAll = false;
      }
      let className = element.className
        ? element.option.correct
          ? "correct"
          : "error"
        : element.option.correct
        ? "correct_1"
        : "";

      // if (
      //   // element.id != selectOption.id ||
      //   isNoScore &&
      //   this.state.multiples <= 1
      // ) {
      //   return { id: element.id, className: "", option: element.option };
      // } else {
      return {
        id: element.id,
        className: className,
        option: element.option,
      };
      // }
    });
    if (this.state.multiples > this.state.selected) {
      correctAll = false;
    }
    // if (!correctAll) {
    //   newSelectOptions = this.state.selectOptions.map((element) => {
    //     return {
    //       id: element.id,
    //       className: "",
    //       option: element.option,
    //     };
    //   });
    // }

    // print(newSelectOptions);
    this.props.pauseReplay();
    let record = this.state.record;
    let pre_answer_count = record.total_answer_count;
    record["total_answer_count"] = pre_answer_count + 1;
    print("this.props.try_round 需要回答次数:" + this.props.try_round);
    print("record['total_answer_count']", record["total_answer_count"]);
    if (!correctAll && record["total_answer_count"] < this.props.try_round) {
      let newSelectOptions = this.state.selectOptions.map((element) => {
        return {
          id: element.id,
          className: "",
          option: element.option,
        };
      });
      this.setState({
        selectOptions: newSelectOptions,
        again:
          record["total_answer_count"] < this.props.try_round ? true : false,
      });
    } else {
      this.setState({
        selectOptions: newSelectOptions,
        again:
          record["total_answer_count"] < this.props.try_round ? true : false,
      });
    }

    if (record["total_answer_count"] < this.props.try_round) {
      // this.setState({
      //   selectOptions: newSelectOptions,
      //   again: correctAll ? false : true,
      // });
      if (correctAll) {
        // return Promise.resolve().then(() => {
        //   let audio = document.getElementById('effect_pass_sound')
        //   audio.play()
        // })
        print("isNoScore", isNoScore);
        this.props
          .promisifiedPlayEffectAudioWithReward(
            isNoScore ? "effect_noscore_sound" : "effect_pass_sound",
            record
          )
          .then((res) => {
            return new Promise((resolve) => {
              const timeOutID = setTimeout(() => {
                // this.delayExcute.call(this);
                resolve();
              }, 500);
              record["end_answer_time"] = new Date();
              // print(selectOption.option.content);
              let arr = "";
              selectOptions.map((ite, idx) => {
                if (ite.className && ite.className !== "correct_1") {
                  arr = arr + (arr ? "," : "") + String(ite.option.content);
                }
              });
              console.log(arr, "===========11111111111");
              if (arr) {
                this.props.operateSingleAnswerText.pushSingleAnswer(arr);
              }

              this.setState({
                timeOutID: timeOutID,
                record: record,
                // selectOptions: newSelectOptions,
              });

              this.props
                .summitRecord(this.state.record)
                .then((res) => {
                  console.log("-----222222222222222222222---res", res);
                  this.answered = false;
                  if (res.data.race_quizzes_result) {
                    this.setState({
                      race_quizzes_result: res.data.race_quizzes_result,
                      nextLessRecord: res.data.lesson_record,
                      showMuilResult: true,
                    });
                  } else {
                    this.props.setLessonRecord(res.data.lesson_record);
                    this.delayExcute();
                  }
                })
                .catch((e) => {
                  console.log(
                    error,
                    " summitQuizRecord(quizRecord, answerText) {"
                  );
                  Raven.captureException(JSON.stringify(e));
                });
              print("----record-success----");
            });
          })
          .then(() => {
            this.setState({
              liked: true,
            });
          })
          .catch((err) => {
            // print(err)
            console.log("----record-success----111111", err);
            Raven.captureException(JSON.stringify(err));
          });
      } else {
        this.props
          .promisifiedPlayEffectAudioWithReward(
            isNoScore ? "effect_noscore_sound" : "effect_fail_human_sound"
          )
          .then((res) => {
            // print(selectOption.option.content);
            this.answered = false;
            let arr = "";
            selectOptions.map((ite, idx) => {
              if (ite.className && ite.className !== "correct_1") {
                arr = arr + (arr ? "," : "") + String(ite.option.content);
              }
            });
            console.log(arr, "===========222222222222222");
            if (arr) {
              this.props.operateSingleAnswerText.pushSingleAnswer(arr);
            }

            return new Promise((resolve) => {
              this.setState({
                record: record,
                // selectOptions: newSelectOptions,
              });
              setTimeout(
                function () {
                  const newSelectOptions = this.state.selectOptions.map(
                    (element) => {
                      return {
                        id: element.id,
                        className: "",
                        option: element.option,
                      };
                    }
                  );
                  this.setState({
                    selectOptions: newSelectOptions,
                    selected: 0,
                  });
                  resolve();
                }.bind(this),
                2000
              );
              print("----record-time_after_time_fail_human-----");
            });
          })
          .then(() => {
            this.setState({
              liked: true,
            });
          })
          .catch((err) => {
            // print(err)
            console.log("----record-time_after_time_fail_human-----", err);
            Raven.captureException(JSON.stringify(err));
          });
      }
    } else if (record["total_answer_count"] == this.props.try_round) {
      if (correctAll) {
        print("isNoScore", isNoScore);
        this.props
          .promisifiedPlayEffectAudioWithReward(
            isNoScore ? "effect_noscore_sound" : "effect_pass_sound",
            record
          )
          .then((res) => {
            let arr = "";
            selectOptions.map((ite, idx) => {
              if (ite.className && ite.className !== "correct_1") {
                arr = arr + (arr ? "," : "") + String(ite.option.content);
              }
            });
            console.log(arr, "===========33333333333");
            if (arr) {
              this.props.operateSingleAnswerText.pushSingleAnswer(arr);
            }
            // console.log(selectOption.option.content);
            // this.props.operateSingleAnswerText.pushSingleAnswer(
            //   selectOption.option.content
            // );
            return new Promise((resolve) => {
              const timeOutID = setTimeout(() => {
                // this.delayExcute.call(this);
                resolve();
              }, 500);
              record["end_answer_time"] = new Date();
              this.setState({
                timeOutID: timeOutID,
                record: record,
                // selectOptions: newSelectOptions,
              });
              this.props
                .summitRecord(this.state.record)
                .then((res) => {
                  console.log(
                    "-----222222222222222222222---res",
                    res.data.race_quizzes_result
                  );
                  this.answered = false;
                  if (res.data.race_quizzes_result) {
                    console.log("000000000000000000000000000000000");
                    this.setState({
                      race_quizzes_result: res.data.race_quizzes_result,
                      nextLessRecord: res.data.lesson_record,
                      showMuilResult: true,
                    });
                  } else {
                    this.props.setLessonRecord(res.data.lesson_record);
                    this.delayExcute();
                  }
                })
                .catch((e) => {
                  console.log(
                    error,
                    " summitQuizRecord(quizRecord, answerText) {"
                  );
                  Raven.captureException(JSON.stringify(e));
                });
              print("----record-one_time-success----");
            });
          })
          .then(() => {
            this.setState({
              liked: true,
            });
          })
          .catch((err) => {
            // print(err)
            console.log("----record-one_time-success----22222222", err);
            Raven.captureException(JSON.stringify(err));
          });
      } else {
        let arr = "";
        selectOptions.map((ite, idx) => {
          if (ite.className && ite.className !== "correct_1") {
            arr = arr + (arr ? "," : "") + String(ite.option.content);
          }
        });
        console.log(arr, "===========4444444444444444");
        if (arr) {
          this.props.operateSingleAnswerText.pushSingleAnswer(arr);
        }

        // print(selectOption.option.content);
        // this.props.operateSingleAnswerText.pushSingleAnswer(
        //   selectOption.option.content
        // );
        this.props
          .promisifiedPlayEffectAudioWithReward(
            isNoScore ? "effect_noscore_sound" : "effect_fail_sound",
            record
          )
          .then((res) => {
            return new Promise((resolve) => {
              const timeOutID = setTimeout(() => {
                // this.delayExcute.call(this);
                resolve();
              }, 500);
              record["end_answer_time"] = new Date();
              this.setState({
                timeOutID: timeOutID,
                record: record,
                // selectOptions: newSelectOptions,
              });
              record["total_answer_count"] += 1;
              this.props
                .summitRecord(this.state.record)
                .then((res) => {
                  console.log("-----222222222222222222222---res", res);
                  this.answered = false;
                  if (res.data.race_quizzes_result) {
                    this.setState({
                      race_quizzes_result: res.data.race_quizzes_result,
                      nextLessRecord: res.data.lesson_record,
                      showMuilResult: true,
                    });
                  } else {
                    this.props.setLessonRecord(res.data.lesson_record);
                    this.delayExcute();
                  }
                })
                .catch((e) => {
                  console.log(
                    error,
                    " summitQuizRecord(quizRecord, answerText) {"
                  );
                  Raven.captureException(JSON.stringify(e));
                });
              // .catch((error) => {
              //   console.log(error, " summitQuizRecord(quizRecord, answerText) {");
              //   Raven.captureException(JSON.stringify(error));
              // })
              print("----record-one_time-fail----");
            });
          })
          .then(() => {
            this.setState({
              liked: true,
            });
          })
          .catch((err) => {
            // print(err)
            console.log("----record-one_time-fail----22222222", err);
            Raven.captureException(JSON.stringify(err));
          });
      }
    }
  }
  // 播放答题音效
  promisifiedPlayEffectAudio = (ElementId) => {
    let audio = document.getElementById(ElementId);
    // console.log(audio.src, "promisifiedPlayEffectAudio111");
    // let times = 0;
    // return request({ url: `${audio.src}?avinfo`, method: "GET" }).then(
    //   (res) => {
    //     times += Math.ceil(Number(res.data.format.duration) * 1000);
    //     console.log(times, "res------------times", res.data.format.duration);
    print(
      "===========promisifiedPlayEffectAudio===========" +
        this.props.recordTimes[ElementId]
    );
    return new Promise((resolve, reject) => {
      audio.play();
      setTimeout(() => {
        resolve();
      }, this.props.recordTimes[ElementId] || 2000);
    });
    //   }
    // );
    // console.log(times, "res------------");
  };

  delayExcute() {
    this.hiddenAnswer();
    this.props.playAudio();
    if (this.state.timeOutID) {
      clearTimeout(this.state.timeOutID);
    }
  }

  showAnswer() {
    let record = this.state.record;
    record["start_answer_time"] = new Date();
    this.setState({
      isDisplay: "block",
      record: record,
      isShowHide: "block",
    });
  }
  handleCountdownEnd() {
    console.log(this, "选择题this");
    if (this.answered) {
      return;
    }
    this.answered = true;
    console.log(this.answered, "answered选择题this", this);
    this.setState({
      showEndtime: 0,
    });
    this.handleSelect(false);
  }
  hiddenAnswer() {
    this.setState({
      isDisplay: "none",
      isShowHide: "none",
    });
  }

  render() {
    let choiceName = null;
    let { isReplaying, indexEnable } = this.props;

    if (indexEnable) {
      choiceName = ["A", "B", "C", "D", "E", "F", "G"];
    }

    return this.state.isDisplay == "block" ? (
      <div
        className="course_answer"
        style={{
          display: this.state.isDisplay,
        }}
      >
        {this.state.isMuilFirst && !this.state.hideReady ? (
          <CountDownTime
            key={this.props.currentQuiz.id + "readygo"}
            endTime={new Date(new Date().getTime() + 5 * 1000)}
            overText="ready go!"
            onEnd={() => {
              this.setState({
                hideReady: true,
                showEndtime:
                  new Date().getTime() + (this.state.limitTimes + 1) * 1000,
              });
            }}
          >
            {({ d, h, m, s }) => {
              return (
                <div className="ready-go">
                  <audio
                    src="https://upload.fireflybaby.cn/race/start_race.mp3"
                    autoPlay
                  ></audio>
                  <img src={ready_go_back}></img>
                  <img src={ready_go + "?" + this.props.currentQuiz.id}></img>
                  {/* <div className="icon_back">
                    <img src={ready_go}></img>
                  </div> */}
                </div>
              );
            }}
          </CountDownTime>
        ) : (
          ""
        )}
        {this.state.hideReady ? (
          this.state.showMuilResult ? (
            <CountDownTime
              key={this.props.currentQuiz.id + "result"}
              // bindTime={this.changeTime.bind(this)}
              endTime={new Date(new Date().getTime() + 7 * 1000)}
              overText="继续学习!"
              onEnd={() => {
                this.setState({ showMuilResult: false }, () => {
                  this.props.setLessonRecord(this.state.nextLessRecord);
                  this.delayExcute();
                });
              }}
            >
              {({ d, h, m, s }) => {
                return (
                  <div className="muil-result">
                    <div
                      className={`muil-result-center ${
                        this.state.race_quizzes_result.correct_count /
                          this.state.race_quizzes_result.total_count >=
                        0.75
                          ? "multiple-pass"
                          : " multiple-fail"
                      } `}
                    >
                      <div className="muil-result-back">
                        <div className="muil-result-quiz-count">
                          <span className="top">
                            <span className="top-count">
                              {this.state.race_quizzes_result.correct_count ||
                                0}
                            </span>
                            题
                          </span>
                          <span>答对</span>
                        </div>
                        <div className="muil-result-quiz-time muil-result-quiz-count">
                          <span className="top">
                            <span className="top-count">
                              {this.state.race_quizzes_result.time || 0}
                            </span>
                            秒
                          </span>
                          <span>用时</span>
                        </div>
                        <div className="muil-result-quiz-moer muil-result-quiz-time muil-result-quiz-count">
                          <span className="top">
                            <span className="top-count">{`${
                              this.state.race_quizzes_result.percent || 0
                            }\%`}</span>
                            的小朋友
                          </span>
                          <span>超过</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </CountDownTime>
          ) : (
            <>
              {this.state.limitTimes ? (
                <div className="count-time">
                  <CountDownTime
                    key={this.props.currentQuiz.id + "countTime"}
                    bindTime={this.changeTime.bind(this)}
                    endTime={
                      new Date(
                        this.state.showEndtime ||
                          new Date().getTime() +
                            (Number(this.state.limitTimes) + 1) * 1000
                      )
                    }
                    overText="答题结束"
                    onEnd={this.handleCountdownEnd.bind(this)}
                  >
                    {({ d, h, m, s }) => {
                      return (
                        <span className="timer">
                          <audio
                            src="https://upload.fireflybaby.cn/race/count_down.mp3"
                            loop="loop"
                            autoPlay
                          ></audio>
                          <img src={count_time_icon}></img>
                          {/* <span>{h}</span>: */}
                          <span>{m}</span>:<span>{s}</span>
                        </span>
                      );
                    }}
                  </CountDownTime>
                </div>
              ) : (
                ""
              )}

              <div className="options">
                <div className="option_description">
                  <ul
                    onClick={this.handleSelect}
                    className={indexEnable ? "options-normal" : ""}
                  >
                    {this.state.selectOptions &&
                    this.state.selectOptions.length > 0
                      ? this.state.selectOptions.map((selectOption, index) => {
                          return (
                            <li
                              key={selectOption.id}
                              id={selectOption.id}
                              data-index={index}
                              data-choice={choiceName && choiceName[index]}
                              className={
                                selectOption.className
                                  ? selectOption.className == "correct_1" &&
                                    this.state.again
                                    ? ""
                                    : selectOption.className
                                  : selectOption.className
                              }
                            >
                              {selectOption.option.content}
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                  {!this.state.limitTimes &&
                  this.state.selectOptions &&
                  this.state.selectOptions.length > 0 ? (
                    <div
                      className={
                        isReplaying ? "read_voice active " : "read_voice"
                      }
                      onClick={this.props.replay}
                    >
                      {this.props.replayText ?? "重听"}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.multiples > 1 ? (
                    <div
                      className={"multiples_selected  "}
                      onClick={() => {
                        if (this.state.selected >= 1) {
                          this.handleMultiplesConfirm();
                        }
                      }}
                      style={{
                        background:
                          this.state.selected <= 0 ? "#FFC000" : "#6AD33B",
                      }}
                    >
                      {this.state.selected <= 0 ? "多选题" : "确定"}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  }
}
