import React from "react";

const Capsule = (props) => {
  const defaultStyle = {
    padding: '2px 8px',
    borderRadius: '22px',
    textAlign: 'center',
  }
  const style = Object.assign({}, defaultStyle, props.style)
  return (
    <div className={props.className} style={style} onClick={props.onClick}>{props.text}</div>
  )
}

export default Capsule; 