import React from "react";
import "../CoursePractice.scss";
import "../CourseQuiz.scss";
export default class RenderButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        onClick={this.props.handleClick}
        className="quiz-next-bottom"
        style={{
          // fontSize: 12,
          background: this.props.answer
            ? ""
            : "linear-gradient(0deg, rgba(91,194,249,.6) 0%, rgba(170,226,255,.6)  100%)",
        }}
      >
        <div className="quiz-next-bottom-flex">
          {this.props.text && <span> {this.props.text}</span>}
          {this.props.icon_img && <img src={this.props.icon_img}></img>}
        </div>
        <div className="buttom-icon">
          <span className="buttom-icon1"></span>
          <span className="buttom-icon2"></span>
        </div>
      </div>
    );
  }
}
