import React from "react";
import PropTypes from "prop-types";
import { debounce, print, tracking } from "../../utils/helpers";
import XinYaNativeBridge from "../../../packs/native_bridges/native_bridge";
import AppDownloadTip from "../../../components/study_packages/poetries/v2/components/AppDownloadTip";
import Raven from "raven-js";
import audio_start_icon from "mini_exams/questionnaire/audio_start_icon.png";
import icon_error from "practice_courses/xinya_down.png";
import audio_ing_icon from "mini_exams/questionnaire/audio_ing_icon.png";
import audio_end_icon from "mini_exams/questionnaire/audio_end_icon.png";
import audio_replay_icon from "mini_exams/questionnaire/audio_replay_icon.png";
import "./PlainRecorder.scss";
// import "../../poetry_articles/PracticeQuestion.scss";
export default class PlainRecorder extends React.Component {
  constructor(props) {
    super(props);
    console.log("=========", props);
    this.AudioRecorderManager = this.createAudioRecorderManager(
      this.props.isRemoveRecord,
      this.props.quizId,
      this.props.babyId,
      this.getCompleteRecordOptions(),
      this.props.authToken,
      this.props.ravenMsg,
      this.props.quizType
    );
    this.AudioRecorderManagerHandlers = this.AudioRecorderManager.initHandlers();
    this.effectiveTime = 0;
    this.state = {
      startRecordBar: true,
    };
  }

  getCompleteRecordOptions() {
    return { isLastQuiz: this.props.isLastQuiz };
  }

  createAudioRecorderManager(
    isRemoveRecord,
    quizId,
    babyId,
    completeRecordOptions,
    authToken,
    ravenMsg,
    quizType
  ) {
    const delegate = function (client, delegation) {
      if (!("buttonPressed" in delegation)) {
        throw new Error("状态必须实现buttonPressed方法");
      }
      return {
        buttonPressed: function () {
          return delegation.buttonPressed.apply(client, arguments);
        },
      };
    };

    // const handleAudioRecordId = (function () {
    //   let localAudioRecordIds = [];
    //   return {
    //     setId: function (localId) {
    //       localAudioRecordIds.length = 0;
    //       localAudioRecordIds.push(localId);
    //       print("localAudioRecordIds", localAudioRecordIds);
    //     },
    //     getId: function () {
    //       return localAudioRecordIds.splice(0, 1)[0];
    //     },
    //     getIdForPlayVoice: function () {
    //       return localAudioRecordIds[0];
    //     },
    //   };
    // })();

    const AudioRecorderFSM = {
      // 开始录音
      startRecord: {
        buttonPressed: function () {
          print("startRecord buttonPressed", "next state is stopRecordState");
          let onRecordStart = Array.prototype.shift.call(arguments);
          let onAutoStopRecord = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            this.currentState = this.stopRecordState;
          };
          // onEndRecord.onVoiceRecordEnd(stateFixOnAutoStopRecord)

          XinYaNativeBridge.startRecord(
            {
              quizId: quizId,
              babyId: babyId,
              authToken: authToken,
              target_type: quizType == "Quiz" ? "Quiz" : "MediaResourceQuiz",
            },
            stateFixOnAutoStopRecord
          );

          onRecordStart();
          this.currentState = this.stopRecordState;

          if (ravenMsg) {
            let msg = ravenMsg;
            msg["quiz_id"] = `${quizId}`;
            let event = `startRecord`;
            tracking(msg, event);
          }
        },
      },
      // 停止录音
      stopRecord: {
        buttonPressed: function () {
          print(
            "stopRecord buttonPressed",
            "next state is playVoice or completeRecord or reStartRecord"
          );
          let onRecordStop = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            this.currentState = this.stopRecordGroupsState;
            print("afterStopRecord", this);
            print("afterStopRecord.currentState", this.currentState);
            onRecordStop();
          };
          XinYaNativeBridge.stopRecord(stateFixOnAutoStopRecord);

          if (ravenMsg) {
            let msg = ravenMsg;
            msg["quiz_id"] = `${quizId}`;
            let event = "stopRecord";
            tracking(msg, event);
          }
        },
      },
      // 播放录音
      playVoice: {
        buttonPressed: function () {
          print(
            "playVoice buttonPressed",
            "next state is playVoice or completeRecord or reStartRecord"
          );
          let onRecordPlay = Array.prototype.shift.call(arguments);
          let onAutoPlayEnd = Array.prototype.shift.call(arguments);
          XinYaNativeBridge.playVoice(onAutoPlayEnd);
          onRecordPlay();
          this.currentState = this.stopRecordGroupsState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "playVoice";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
      // 结束录音
      completeRecord: {
        buttonPressed: function () {
          print("completeRecord buttonPressed");
          let onRecordComplete = Array.prototype.shift.call(arguments);
          // onRecordComplete()
          XinYaNativeBridge.completeRecord(
            completeRecordOptions,
            onRecordComplete
          );
          this.currentState = this.stopRecordGroupsState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "completeRecord";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
      reStartRecord: {
        buttonPressed: function () {
          print("reStartRecord buttonPressed", "next state is stopRecordState");
          let onRecordStart = Array.prototype.shift.call(arguments);
          let onAutoStopRecord = Array.prototype.shift.call(arguments);
          let stateFixOnAutoStopRecord = () => {
            onAutoStopRecord();
            this.currentState = this.stopRecordState;
          };
          // onEndRecord.onVoiceRecordEnd(stateFixOnAutoStopRecord)

          XinYaNativeBridge.reStartRecord(stateFixOnAutoStopRecord);
          onRecordStart();
          this.currentState = this.stopRecordState;
          if (ravenMsg) {
            let msg = ravenMsg;
            let event = "reStartRecord";
            msg["quiz_id"] = `${quizId}`;
            tracking(msg, event);
          }
        },
      },
    };

    let AudioRecorder = function () {
      let playVoiceState = delegate(this, AudioRecorderFSM.playVoice);
      let completeRecordState = delegate(this, AudioRecorderFSM.completeRecord);
      let startRecordState = delegate(this, AudioRecorderFSM.startRecord);
      let reStartRecordState = delegate(this, AudioRecorderFSM.reStartRecord);

      this.startRecordState = startRecordState;
      this.stopRecordState = delegate(this, AudioRecorderFSM.stopRecord);
      this.stopRecordGroupsState = {
        playVoiceState: playVoiceState,
        completeRecordState: completeRecordState,
        reStartRecord: reStartRecordState,
      };

      this.currentState = this.startRecordState; // 初始化状态--开始录音
    };

    // AudioRecorder.prototype.getAudioRecordId = function () {
    //   return handleAudioRecordId.getId();
    // };

    AudioRecorder.prototype.initHandlers = function () {
      let handleAudioRecorderStart = (onRecordStart, onAutoStopRecord) => {
        print("handleAudioRecorderStart");
        this.startRecordState.buttonPressed.call(
          null,
          onRecordStart,
          onAutoStopRecord
        );
      };
      let handleAudioRecorderStop = (onRecordStop) => {
        print("handleAudioRecorderStop");
        print("handleAudioRecorderStop this", this);
        print("handleAudioRecorderStop this.currentState", this.currentState);
        try {
          this.stopRecordState.buttonPressed.call(null, onRecordStop);
        } catch (e) {
          Raven.captureException(e);
        }
      };
      let handleAudioRecorderPlay = (onRecordPlay, onAutoPlayEnd) => {
        print("handleAudioRecorderPlay");
        this.stopRecordGroupsState.playVoiceState.buttonPressed.call(
          null,
          onRecordPlay,
          onAutoPlayEnd
        );
      };
      let handleAudioRecorderComplete = (onRecordComplete) => {
        print("handleAudioRecorderComplete");
        this.stopRecordGroupsState.completeRecordState.buttonPressed.call(
          null,
          onRecordComplete
        );
      };
      let handleAudioRecorderReStart = (onRecordReStart, onAutoStopRecord) => {
        print("handleAudioRecorderReStart");
        this.stopRecordGroupsState.reStartRecord.buttonPressed.call(
          null,
          onRecordReStart,
          onAutoStopRecord
        );
      };
      return {
        start: handleAudioRecorderStart,
        stop: handleAudioRecorderStop,
        play: handleAudioRecorderPlay,
        complete: handleAudioRecorderComplete,
        reStart: handleAudioRecorderReStart,
      };
    };

    return new AudioRecorder();
  }

  handleAudioRecorderStart = () => {
    this.startTime = new Date();
    this.props.pauseReplay();
    const onRecordStart = () => {
      this.setState(() => ({
        startRecordBar: false,
        onRecordingBar: true,
      }));

      this.props.onRecordStartAction();
    };
    const onAutoStopRecord = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      // this.props.onRecordCompleteAction(this.AudioRecorderManager.getAudioRecordId)
    };
    this.AudioRecorderManagerHandlers.start(onRecordStart, onAutoStopRecord);
  };

  handleAudioRecorderStop = () => {
    const onRecordStop = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      this.props.onRecordStopAction();
    };
    this.AudioRecorderManagerHandlers.stop(onRecordStop);
  };

  handleAudioRecorderPlay = () => {
    if (this.state.recordIsPlaying) {
      return;
    }
    this.setState({
      recordIsPlaying: true,
    });
    const onRecordPlay = () => {};
    const onAutoPlayEnd = () => {
      this.setState({
        recordIsPlaying: false,
      });
    };
    this.AudioRecorderManagerHandlers.play(onRecordPlay, onAutoPlayEnd);
  };

  handleAudioRecorderComplete = () => this.onRecordCompleteAction();

  onRecordCompleteAction() {
    // this.props.quizThis.answered = true;
    this.effectiveTime += new Date() - this.startTime;
    this.props.setThisAnswered && this.props.setThisAnswered(true);

    const onRecordComplete = () => {
      this.setState(() => ({
        endRecordBar: false,
        recordIsPlaying: false,
      }));

      this.props.onRecordCompleteAction(
        // this.AudioRecorderManager.getAudioRecordId
        null,
        this.effectiveTime
      );
    };
    this.AudioRecorderManagerHandlers.complete(onRecordComplete);
  }

  handleAudioRecorderReStart = () => {
    this.setState(() => ({
      onRecordingBar: true,
      endRecordBar: false,
      recordIsPlaying: false,
    }));
    const onRecordReStart = () => {
      this.props.onRecordReStartAction();
    };
    const onAutoStopRecord = () => {
      this.setState(() => ({
        onRecordingBar: false,
        endRecordBar: true,
      }));
      // this.props.onRecordCompleteAction(this.AudioRecorderManager.getAudioRecordId)
    };
    this.AudioRecorderManagerHandlers.reStart(
      onRecordReStart,
      onAutoStopRecord
    );
  };

  handleNotSupportRecorder = () => {
    this.props.onRecordCompleteAction();
    this.props.pauseReplay();
  };

  render() {
    const {
      startRecordBar,
      onRecordingBar,
      endRecordBar,
      recordIsPlaying,
    } = this.state;
    const {
      isLastQuiz,
      isRemoveRecord,
      isNotSupportRecorder,
      showCount,
      isReplaying,
      replayAudio,
    } = this.props;
    return this.props.quizType && this.props.quizType == "Quiz" ? (
      <div
        className="c4_recorder"
        style={{ marginBottom: isNotSupportRecorder ? 70 : "" }}
      >
        {/* <div
          className={
            recordIsPlaying
              ? "play_record_active common_record_style"
              : "play_record  common_record_style"
          }
          onClick={debounce(this.handleAudioRecorderPlay, 1000)}
        >
          播放
        </div> */}
        {isNotSupportRecorder && showCount == 1 && <AppDownloadTip />}
        {startRecordBar &&
          (!isNotSupportRecorder ? (
            <div
              className="start_record"
              onClick={
                isNotSupportRecorder
                  ? this.handleNotSupportRecorder.bind(this)
                  : debounce(this.handleAudioRecorderStart, 1000)
              }
            >
              <img
                src={audio_start_icon}
                className="question_audio_start_icon"
              ></img>
              <div className="question_audio_start">
                {isNotSupportRecorder ? (
                  <>
                    <div>没有录音功能,请扫描二维码下载</div>
                    <div>【心芽学堂】APP进行学习呦</div>
                  </>
                ) : (
                  "点击按钮开始录音"
                )}
              </div>
            </div>
          ) : (
            <div className="start_record">
              <img src={icon_error} className="question_audio_start_icon"></img>
              <div className="question_audio_start">
                {isNotSupportRecorder ? (
                  <>
                    <div>没有录音功能,请扫描二维码下载</div>
                    <div>【心芽学堂】APP进行学习呦</div>
                  </>
                ) : (
                  "点击按钮开始录音"
                )}
              </div>
            </div>
          ))}
        {onRecordingBar && (
          <div
            className={`on_recording ${
              isRemoveRecord ? "no_record_skill" : ""
            }`}
            onClick={debounce(this.handleAudioRecorderStop, 1000)}
          >
            <>
              {isNotSupportRecorder ? (
                "没有录音功能，点击结束"
              ) : (
                <>
                  <img
                    src={audio_ing_icon}
                    className="question_audio_start_icon"
                  ></img>
                  <div className="question_audio_start">点击按钮结束录音</div>
                </>
              )}
            </>
          </div>
        )}
        {endRecordBar && (
          <div
            className="recording"
            // style={{ justifyContent: isRemoveRecord ? "center" : "" }}
          >
            {!isNotSupportRecorder && (
              <div
                className={
                  recordIsPlaying
                    ? "play_record_active common_record_style"
                    : "play_record  common_record_style"
                }
                onClick={debounce(this.handleAudioRecorderPlay, 1000)}
              >
                <span>播放</span>
              </div>
            )}
            <div className="end_recording">
              {!isNotSupportRecorder && (
                <div
                  className="re_record "
                  onClick={debounce(this.handleAudioRecorderReStart, 1000)}
                  style={{ marginRight: 66 }}
                >
                  <img
                    src={audio_replay_icon}
                    className="question_audio_start_icon"
                  ></img>
                  <div>重录</div>
                </div>
              )}
              <div
                className="complete_record "
                onClick={debounce(this.handleAudioRecorderComplete, 1000)}
              >
                <img
                  src={audio_end_icon}
                  className="question_audio_start_icon"
                ></img>
                <div>提交</div>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="bar_for_recorder">
        {isNotSupportRecorder && showCount == 1 && <AppDownloadTip />}
        {startRecordBar && (
          <>
            <div
              id="start_record"
              className="start_record"
              onClick={
                isNotSupportRecorder
                  ? this.handleNotSupportRecorder.bind(this)
                  : debounce(this.handleAudioRecorderStart, 1000)
              }
            >
              开始
            </div>
            {!this.props.showRePlaying ? (
              <div
                className={isReplaying ? "read_voice active " : "read_voice"}
                onClick={replayAudio}
              >
                 {this.props.replayText ?? '重听'}
              </div>
            ) : (
              ""
            )}
          </>
        )}
        {onRecordingBar && (
          <div
            id="on_recording"
            className={`on_recording ${
              isRemoveRecord ? "no_record_skill" : ""
            }`}
            onClick={debounce(this.handleAudioRecorderStop, 1000)}
          >
            {isRemoveRecord ? "没有录音功能，点击结束" : "录音中，点击结束"}
          </div>
        )}

        {endRecordBar && !this.props.showAnalysis && (
          <div
            className="end_recording"
            style={{ justifyContent: isRemoveRecord ? "center" : "" }}
          >
            {!isRemoveRecord && (
              <div
                className={
                  recordIsPlaying
                    ? "play_record_active common_record_style"
                    : "play_record  common_record_style"
                }
                onClick={debounce(this.handleAudioRecorderPlay, 1000)}
              >
                播放
              </div>
            )}
            {!this.props.showAnalysis && (
              <>
                <div
                  id="complete_record"
                  className="complete_record common_record_style"
                  onClick={debounce(this.handleAudioRecorderComplete, 1000)}
                >
                  完成
                </div>
                {!isRemoveRecord && (
                  <div
                    className="re_record common_record_style"
                    onClick={debounce(this.handleAudioRecorderReStart, 1000)}
                  >
                    重读
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}
PlainRecorder.propTypes = {
  // startRecordBar: PropTypes.bool.isRequired,
  // onRecordingBar: PropTypes.bool.isRequired,
  // endRecordBar: PropTypes.bool.isRequired,
  onRecordStartAction: PropTypes.func.isRequired,
  onRecordStopAction: PropTypes.func.isRequired,
  onRecordCompleteAction: PropTypes.func.isRequired,
  onRecordReStartAction: PropTypes.func.isRequired,
  isRemoveRecord: PropTypes.bool.isRequired,
};
