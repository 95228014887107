import React from "react";
import PropTypes from "prop-types";
import "./CustomSmallIcon.scss";

const CustomSmallIcon = ({textContent, customStyle, customTextStyle, icon, customClassName}) => {
  customClassName = customClassName ?? 'custom-small-icon'
  const imgStyle = {
    width: '22px',
    height: '22px',
    position: 'absolute',
    top: '50%',
    left: '-5px',
    transform: 'translateY(-50%)',
    opacity: 1,
    zIndex: 1,
  }
  let textStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    // padding: '0 16px',
    // borderTopRightRadius: '15px',
    // borderBottomRightRadius: '15px',
    width: '66px',
    borderRadius: '15px',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
  textStyle = Object.assign(textStyle, customTextStyle)
  return (
    <div className={customClassName} style={customStyle}>
      <img src={icon} style={imgStyle}/>
      <div style={textStyle}>
        <div style={{transform: 'translateX(5px)'}}>{textContent}</div>
      </div>
    </div>
  )
}

CustomSmallIcon.propTypes = {
  textContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  icon: PropTypes.string.isRequired,
}

export default CustomSmallIcon