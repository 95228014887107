import PlainRecorder from "./PlainRecorder";
import { SOE, SoeLog } from "../../utils/soe";
import { ToastManager } from "../../utils/Toast";

export default class SOERecorder extends PlainRecorder {
  constructor(props) {
    super(props);
    console.log(props, "SOERecorder");
    this.state.answerText = [];
    this.state.totalAnswerCount = 0;
  }

  getCompleteRecordOptions() {
    return {};
  }

  onRecordCompleteAction() {
    // this.props.quizThis.answered = true;
    this.props.setThisAnswered && this.props.setThisAnswered(true);
    this.effectiveTime += new Date() - this.startTime;
    // this.props.setThisAnswered(true);
    const onRecordComplete = () => {
      this.setState(() => ({
        endRecordBar: false,
        recordIsPlaying: false,
      }));

      XinYaNativeBridge.getLastAudioData((data) => {
        this.evaluateAudio(data);
      });
    };
    this.AudioRecorderManagerHandlers.complete(onRecordComplete);
  }

  evaluateAudio(audioData) {
    let quiz = this.props.quiz;
    let text = quiz.answer;
    // const toast = ToastManager.showLoading("语音上传中");
    SOE(audioData, text, (r) => {
      let type = this.props.quizType == "Quiz" ? "Quiz" : "MediaResourceQuiz";
      SoeLog(type, quiz.id, text, r).then((data) => {
        let resp = r.Response;
        // if (resp.Status === "Finished") {
        // } else if (resp.Error) {
        //   alert(resp.Error.Message);
        // }

        if (resp.Error) {
          resp.SuggestedScore = 0;
        }

        if (this.props.quizType == "Quiz") {
          let score = resp.SuggestedScore;
          this.state.answerText.push(score);
          this.state.totalAnswerCount++;
          this.props.onRecordCompleteAction(
            {
              answerText: this.state.answerText,
              totalAnswerCount: this.state.totalAnswerCount,
              score,
              data,
              audioData,
            },
            this.effectiveTime
          );
          return;
        } else {
          let score = resp.SuggestedScore;
          this.state.answerText.push(score);
          this.state.totalAnswerCount++;
          quiz.total_answer_count = this.state.totalAnswerCount;
          if (score >= 60) {
            this.props
              .promisifiedPlayEffectAudioWithReward("effect_pass_sound", quiz)
              .then(() =>
                this.props.onRecordCompleteAction(
                  {
                    answerText: this.state.answerText,
                    totalAnswerCount: this.state.totalAnswerCount,
                    data,
                  },
                  this.effectiveTime
                )
              );
          } else {
            let have_additional_chances =
              this.state.totalAnswerCount < quiz.try_round;
            let sound_effect = have_additional_chances
              ? "effect_fail_human_sound"
              : "effect_fail_sound";
            this.props
              .promisifiedPlayEffectAudioWithReward(sound_effect)
              .then(() => {
                if (have_additional_chances) {
                  this.setState({
                    startRecordBar: true,
                  });
                } else {
                  this.state.totalAnswerCount++;
                  this.props.onRecordCompleteAction(
                    {
                      answerText: this.state.answerText,
                      totalAnswerCount: this.state.totalAnswerCount,
                      data,
                    },
                    this.effectiveTime
                  );
                }
              });
            }
          }
      });
    });
  }

  // 播放答题音效
  // promisifiedPlayEffectAudio = (ElementId) => {
  //   let audio = document.getElementById(ElementId);
  //   // console.log(audio.src, "promisifiedPlayEffectAudio111");
  //   // let times = 0;
  //   // return request({ url: `${audio.src}?avinfo`, method: "GET" }).then(
  //   //   (res) => {
  //   //     times += Math.ceil(Number(res.data.format.duration) * 1000);
  //   //     console.log(times, "res------------times", res.data.format.duration);
  //   return new Promise((resolve, reject) => {
  //     // let audio = document.getElementById(ElementId);
  //     // print("===========promisifiedPlayEffectAudio===========");
  //     // print(ElementId);
  //     print(
  //       "===========promisifiedPlayEffectAudio===========" +
  //         this.props.recordTimes[ElementId]
  //     );
  //     audio.play();
  //     setTimeout(() => {
  //       resolve();
  //     }, this.props.recordTimes[ElementId] || 2000);
  //   }).catch((err) => {
  //     console.log(err, "------------");
  //   });
  //   //   }
  //   // );
  // };
}
