import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import "./AwesomeKeyboard.scss";
import closeKeyboard from "icon_ketboard_close.png";
import deteleInput from "icon_keyboard_delete.png";
export default class AwesomeKeyboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: [],
      isDisplay: false,
      lastInput: "",
    };
  }

  tapKey = (e) => {
    // console.log(e.target)
    // console.log(e.targetTouches[0])
    let input = e.target.getAttribute("data-va");
    this.setState({
      lastInput: input,
    });
    let parseIntInput = parseInt(input);
    if (!isNaN(parseIntInput) && input) {
      this.state.inputValue.push(parseIntInput);
      this.setState(
        {
          inputValue: this.state.inputValue,
        },
        () => {
          console.log(this.state.inputValue);
        }
      );
    }
    setTimeout(() => {
      this.setState({
        lastInput: "",
      });
    }, 300);
  };
  deleteInput = () => {
    this.state.inputValue.pop();
    this.setState(
      {
        inputValue: this.state.inputValue,
      },
      () => {
        console.log(this.state.inputValue);
      }
    );
  };
  triggerKeyboard = (toDisplay) => {
    return new Promise((resolve, reject) => {
      if (!toDisplay) {
        this.setState({
          inputValue: [],
        });
      }
      this.setState({
        isDisplay: toDisplay,
      });
      resolve();
    });
  };
  handlePreventDefa = (e) => {
    e.preventDefault();
  };
  componentDidMount() {
    window.addEventListener("orientationchange", () => {
      this.setState({
        isDisplay: false,
      });
    });
  }
  render() {
    const { inputValue, lastInput } = this.state;

    if (this.props.forcesToLeft) {
      return (
        <div className="forcesToLeft_wrapp_width_keyboard">
          <div
            className="forcesToLeft_wrapped_component"
            style={{ bottom: this.state.isDisplay ? `100px` : "0px" }}
          >
            {this.props.render(
              inputValue.join(""),
              this.triggerKeyboard,
              this.state.isDisplay
            )}
          </div>
          <div
            className="awesome_keyboard"
            key="keyboard"
            style={{
              top: `${document.body.clientWidth - 100}px`,
              display: this.state.isDisplay ? "block" : "none",
            }}
          >
            <div
              className="awesome_keyboard_column"
              onTouchStart={this.tapKey}
              onTouchEnd={this.handlePreventDefa}
            >
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div
                  data-va={item}
                  key={item}
                  style={{
                    background:
                      parseInt(lastInput) === parseInt(item) ? "lightgrey" : "",
                  }}
                >
                  {item}
                </div>
              ))}
              <div
                className="delete"
                data-va="delete"
                onTouchStart={this.deleteInput}
                onTouchEnd={this.handlePreventDefa}
                style={{
                  backgroundColor: lastInput === "delete" ? "lightgrey" : "",
                }}
              ></div>
            </div>
            <div
              className="awesome_keyboard_column padding_top"
              onTouchStart={this.tapKey}
              onTouchEnd={this.handlePreventDefa}
            >
              {[5, 6, 7, 8, 9].map((item, index) => (
                <div
                  data-va={item}
                  key={item}
                  style={{
                    background:
                      parseInt(lastInput) === parseInt(item) ? "lightgrey" : "",
                  }}
                >
                  {item}
                </div>
              ))}
              <div
                data-va="close"
                className="close"
                onTouchStart={() => this.triggerKeyboard(false)}
                style={{
                  backgroundColor: lastInput === "close" ? "lightgrey" : "",
                }}
                onTouchEnd={this.handlePreventDefa}
              ></div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrapp_width_keyboard">
          <div
            className="wrapped_component"
            style={{
              height: this.state.isDisplay
                ? `${document.body.clientHeight - 100}px`
                : "",
              position: this.state.isDisplay ? "relative" : "",
            }}
          >
            {this.props.render(
              inputValue.join(""),
              this.triggerKeyboard,
              this.state.isDisplay
            )}
          </div>
          <div
            className="awesome_keyboard"
            key="keyboard"
            style={{
              height: `${100}px`,
              display: this.state.isDisplay ? "block" : "none",
            }}
          >
            <div
              className="awesome_keyboard_column"
              onTouchStart={this.tapKey}
              onTouchEnd={this.handlePreventDefa}
            >
              {[0, 1, 2, 3, 4].map((item, index) => (
                <div
                  data-va={item}
                  key={item}
                  style={{
                    background:
                      parseInt(lastInput) === parseInt(item) ? "lightgrey" : "",
                  }}
                >
                  {item}
                </div>
              ))}
              <div
                className="delete"
                data-va="delete"
                onTouchStart={this.deleteInput}
                onTouchEnd={this.handlePreventDefa}
                style={{
                  backgroundColor: lastInput === "delete" ? "lightgrey" : "",
                }}
              ></div>
            </div>
            <div
              className="awesome_keyboard_column padding_top"
              onTouchStart={this.tapKey}
              onTouchEnd={this.handlePreventDefa}
            >
              {[5, 6, 7, 8, 9].map((item, index) => (
                <div
                  data-va={item}
                  key={item}
                  style={{
                    background:
                      parseInt(lastInput) === parseInt(item) ? "lightgrey" : "",
                  }}
                >
                  {item}
                </div>
              ))}
              <div
                data-va="close"
                className="close"
                onTouchStart={() => this.triggerKeyboard(false)}
                style={{
                  backgroundColor: lastInput === "close" ? "lightgrey" : "",
                }}
                onTouchEnd={this.handlePreventDefa}
              ></div>
            </div>
          </div>
        </div>
      );
    }
  }
}
