import React from "react";
import ReactDOM from "react-dom";
// import icon_rate from
import "./MediumPlaybackRateController.scss";
import { GA_new } from "../utils/Env.js";
import CustomSmallIcon from "../shared/CustomSmallIcon";
import Rocket from "rocket";
import { csrfHeaders, request } from "../utils/request";
import { debounce } from "../utils/helpers";

const RateArray = [
  { text: "0.5X", value: 0.5 },
  { text: "1.0X", value: 1 },
  { text: "1.25X", value: 1.25 },
  { text: "1.5X", value: 1.5 },
];

class ControllerUI extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "ControllerUI");
    let selectedItem = { value: 1 };
    if (
      this.props.controller.wrappedMedia[0].props.speed &&
      this.props.controller.wrappedMedia[0].props.speed !== "1.0X"
    ) {
      for (let index = 0; index < RateArray.length; index++) {
        if (
          RateArray[index].text ===
          this.props.controller.wrappedMedia[0].props.speed
        ) {
          selectedItem = RateArray[index];
          this.props.controller.setPlaybackRate(RateArray[index].value);
        }
      }
    }

    this.state = {
      slowerDisabled: false,
      fasterDisabled: false,
      selectedItem,
      showRate: false,
    };
  }
  sendData(item) {
    // GA_new(
    //   "media_lessons_show",
    //   "change_speed",
    //   index,
    //   this.props.controller.wrappedMedia[0].props.target_type,
    //   this.props.controller.wrappedMedia[0].props.target_id
    // );
    console.log(item, "item-,,,,,,,,,,,,");
    request({
      url: this.props.controller.wrappedMedia[0].props.setting_url,
      method: "POST",
      data: {
        speed: item.text,
      },
      headers: csrfHeaders,
    }).then((res) => {
      this.setState(
        {
          selectedItem: item,
          showRate: false,
        },
        () => {
          this.props.controller.setPlaybackRate(item.value);
        }
      );
    });
  }
  updatePlaybackRate(faster) {
    const controller = this.props.controller;
    let playbackRate = controller.getPlaybackRate();
    if (faster && playbackRate < 2) {
      playbackRate += 0.25;
    } else if (playbackRate > 0.5) {
      playbackRate -= 0.25;
    }
    this.setState({
      slowerDisabled: playbackRate <= 0.5,
      fasterDisabled: playbackRate >= 2,
    });
    controller.setPlaybackRate(playbackRate);
  }

  render() {
    return (
      <div>
        <div
          onClick={() => {
            this.setState({
              showRate: true,
            });
          }}
        >
          <CustomSmallIcon
            icon={Rocket}
            textContent={this.state.selectedItem?.text || "倍速"}
            customClassName="audio-playback-rate-controller"
            customStyle={{ position: "relative" }}
          />
          {/* {this.state.selectedItem?.text || "倍速"} */}
        </div>
        {this.state.showRate ? (
          <div
            className="rate-back"
            onClick={() => {
              this.setState({
                showRate: false,
              });
            }}
          >
            <div className="rate-lists">
              {RateArray.map((item, index) => {
                return (
                  <div
                    key={item.text + "r"}
                    className={`lists-item ${
                      this.state.selectedItem.value == item.value
                        ? "selected-rate"
                        : ""
                    }`}
                    data-text={item}
                    data-speed={item}
                    onClick={debounce(() => {
                      console.log(item, "item--------------");
                      this.sendData(item);
                    })}
                  >
                    {item.text}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* <select>
          <option value={1}>倍数</option>
          <option value={1}>倍数</option>
          <option value={1}>倍数</option>
          <option value={1}>倍数</option>
        </select> */}
        {/* <button onClick={() => this.updatePlaybackRate(false)} disabled={this.state.slowerDisabled}>&lt;&lt;慢速</button>
        <button onClick={() => this.updatePlaybackRate(true)} disabled={this.state.fasterDisabled}>快速&gt;&gt;</button> */}
      </div>
    );
  }
}

class MediumPlaybackRateController {
  constructor() {
    this.playbackRate = 1.0;
    this.wrappedMedia = [];
  }

  createUI() {
    if (this.element) {
      return;
    }
    this.element = document.createElement("div");
    this.element.setAttribute("id", "audio-playback-rate-controller");
    document
      .getElementsByClassName("media-lesson-top-right-icons")[0]
      .appendChild(this.element);
    ReactDOM.render(
      React.createElement(ControllerUI, { controller: this }),
      this.element
    );
  }

  setPlaybackRate(playbackRate) {
    this.playbackRate = playbackRate;
    console.log(playbackRate, "111111设置的速度");
    this.wrappedMedia.forEach((wrappedMedium) => {
      if (wrappedMedium.props.playbackRateEnabled) {
        wrappedMedium.audioEl.playbackRate = playbackRate;
      }
    });
  }

  getPlaybackRate() {
    return this.playbackRate;
  }

  register(wrappedMedium) {
    if (this.wrappedMedia.indexOf(wrappedMedium) === -1) {
      let element = RateArray[0];
      // if (wrappedMedium.props.speed) {
      //   for (let index = 1; index < RateArray.length; index++) {
      //     if (RateArray[index].text === wrappedMedium.props.speed) {
      //       element = RateArray[index];
      //       break;
      //     }
      //   }
      // }
      this.createUI();

      this.wrappedMedia.push(wrappedMedium);
      // this.setState(
      //   {
      //     selectedItem: element,
      //   },
      //   () => {
      //     this.props.controller.setPlaybackRate(element.value);
      //   }
      // );
    }
  }

  unregister(wrappedMedium) {
    let index = this.wrappedMedia.indexOf(wrappedMedium);
    if (index !== -1) {
      this.wrappedMedia.splice(index, 1);
    }
  }
}

export default new MediumPlaybackRateController();
