/* eslint-disable no-inner-declarations */
import Raven from "raven-js";
const NetworkStore = [];

const BWDetect = function (time, callback) {
  if (navigator && !navigator.onLine) {
    NetworkStore.push({
      source: "navigator",
      effectiveType: navigator?.connection?.effectiveType ?? null,
      status: "offline",
    });
    // Raven.captureMessage(JSON.stringify(NetworkStore), { level: "info" });
    return;
  }
  try {
    time = time || 10;
    let startTime, endTime, fileSize;
    let count = time;
    function measureBWSimple() {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          if (!fileSize) {
            fileSize = xhr.responseText.length;
          }
          count--;
          if (count <= 0) {
            endTime = Date.now();
            let speed =
              (fileSize * time) / ((endTime - startTime) / 1000) / 1024;
            speed = Math.floor(speed);
            const downlink = navigator?.connection?.downlink;
            NetworkStore.push({
              source: "xhr",
              effectiveType: navigator?.connection?.effectiveType ?? null,
              downlink: downlink ? `${(downlink * 1024) / 8}KB/sec` : null,
              bw: `${speed} KB/sec`,
            });
            console.log(`${speed} KB/sec`);
            // if (speed < 500) {
            //   Raven.captureMessage(JSON.stringify(NetworkStore), {
            //     level: "info",
            //   });
            // }
          }
        }
      };
      xhr.addEventListener("error", (e) => {
        console.log(e);
        if (count === 5) {
          callback && callback();
        }

        // Raven.captureMessage();
        Raven.captureException(
          `BWDetect error count ${count}+${JSON.stringify(e)}`
        );
      });
      xhr.open(
        "GET",
        `https://upload.fireflybaby.cn/quiz/7578/8a8bde01f017f5c496ffe2ab86b538e7.png?t=${Date.now()}`,
        true
      );
      xhr.setRequestHeader("If-Modified-Since", "0");
      xhr.setRequestHeader("Cache-Control", "no-cache");
      xhr.send();
    }
    // const metaElement = document.querySelector('meta[name="csrf-token"]');

    // let csrfToken;
    // if (metaElement && metaElement instanceof window.HTMLMetaElement) {
    //   csrfToken = metaElement.content;
    // }
    // // console.log(metaElement, "ssssssssssssssssssssssssss", csrfToken);
    // const csrfHeaders = {
    //   "X-CSRF-Token": csrfToken,
    //   "X-Requested-With": "XMLHttpRequest",
    // };
    // axios({
    //   url: "/net_monitors",
    //   method: "POST",
    //   // data: {},
    //   headers: csrfHeaders,
    // }).then((res) => {
    //   console.log(res, "---------------------");
    // });
    // callback && callback();
    startTime = Date.now();
    for (let x = time; x > 0; x--) {
      measureBWSimple();
    }
  } catch (e) {
    console.error(e);
    Raven.captureException(JSON.stringify(e));
  }
};

export default BWDetect;
