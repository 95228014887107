import React, { useEffect } from "react";
import Crystal from "point_rewards/crystal";
import Ray from "point_rewards/rays.png";
import PropTypes from "prop-types";
import Capsule from "../shared/Capsule";
import BabyPointIcon from "./BabyPointIcon";
import "./FullScreenCrystalReward.scss";

const CrystalReward = ({ point, totalPoint, newPointStyle, newCrystalStyle, newComponentStyle, textUrl, handleClick }) => {
  console.log(point, totalPoint,"qqq");
  let pointStyle = {
    color: '#FFC000',
    fontSize: '28px',
    fontWeight: 'bold',
  }
  let crystalStyle = {
    marginLeft: '15px',
    width: '74px',
    height: '74px',
  }
  useEffect(() => {
    const audio = document.getElementById('reward-sound')
    setTimeout(() => {
      audio.play()
    }, 1000)
  }, [])
  pointStyle = Object.assign(pointStyle, newPointStyle)
  crystalStyle = Object.assign(crystalStyle, newCrystalStyle)
  return (
    <div className="full-screen-crystal-reward-compoent" style={newComponentStyle}>
      <div className="background-mask"></div>
      <BabyPointIcon point={totalPoint} customTextStyle={{opacity: 0.8}} customStyle={{zIndex: 1001}} />
      <div className="reward-container">
        <div className="crystal-with-ray">
          <img src={Ray} className="ray"/>
          <img src={Crystal} className="crystal"/>
        </div>
        <img src={textUrl} className="reward-text"/>
        <div className="point-and-crystal">
          <div className="point">+{point}</div>
          <img src={Crystal} className="crystal"/>
        </div>
        <Capsule text="确认" className="confirm-button" style={{backgroundColor: '#FFC000', color: 'white', padding: '10px 50px', fontSize: '16px'}} onClick={handleClick} />
        <audio src="https://upload.fireflybaby.cn/file/6a7c0bfca10f54cd3ce68eae7a49aff8.mp3" id="reward-sound"></audio>
      </div>
    </div>
  )
}

CrystalReward.propTypes = {
  point: PropTypes.number.isRequired,
  totalPoint: PropTypes.number.isRequired,
  newPointStyle: PropTypes.object,
  newCrystalStyle: PropTypes.object,
  newComponentStyle: PropTypes.object,
  textUrl: PropTypes.string.isRequired,
}


export default CrystalReward