import React from "react";
import URI from "jsuri";
import queryString from "query-string";
import Raven from "raven-js";
import { css } from "styled-components";
import UserAgent from "../utils/UserAgent";
// import { Object } from 'core-js';
import { isDevelopment } from "./Env";
import { Dialog, DialogManager } from "./Dialog";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import { ToastManager } from "../utils/Toast";
import { format } from "../utils/formatAudioTime";
import localStoragePolyFill from "./localStorage-polyfill.js";

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 414,
  miniPhone: 320,
  aboveTablet: 500,
  aboveDesktop: 992,
};

// Iterate through the sizes and create a media template
/* istanbul ignore next */
export const media = Object.keys(sizes).reduce((acc, label) => {
  if (label.includes("above")) {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label] / 16}em) {
        ${css(...args)}
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label] / 16}em) {
        ${css(...args)}
      }
    `;
  }

  return acc;
}, {});

export const judgeStringType = (answer) => {
  if (typeof answer == "string") {
    return answer.trim();
  }
  return answer;
};

/* istanbul ignore next */
export const defaultFunc = () => {};

/* istanbul ignore next */
export const awaitWrapper = (pFn) => {
  return (...args) =>
    Promise.resolve(typeof pFn === "function" ? pFn.apply(null, args) : pFn)
      .then((res) => [null, res])
      .catch((error) => [error, null]);
};

// 检测参数的数据类型
/* istanbul ignore next */
export const detectedTypeOf = (obj) => {
  const class2type = {};
  "Boolean Number String Function Array Date RegExp Object Error"
    .split(" ")
    .forEach(function (e, i) {
      class2type["[object " + e + "]"] = e.toLowerCase();
    });

  function _typeof(obj) {
    if (obj == null) {
      return String(obj);
    }
    return typeof obj === "object" || typeof obj === "function"
      ? class2type[class2type.toString.call(obj)] || "object"
      : typeof obj;
  }
  return _typeof(obj);
};

/* istanbul ignore next */
export const appendUrlQuery = (originalLocation, extraQueryObj) => {
  if (detectedTypeOf(extraQueryObj) !== "object") {
    console.error("must pass extraQueryObj as a object");
    return;
  }
  const uri = new URI(originalLocation);
  const hash = uri.anchor();
  const query = queryString.parse(uri.query());
  const parsedLocation = queryString.parseUrl(originalLocation);
  Object.keys(extraQueryObj).forEach((k) => {
    query[k] = extraQueryObj[k];
  });
  return decodeURIComponent(
    parsedLocation.url +
      "?" +
      queryString.stringify(query) +
      (hash ? `#${hash}` : "")
  );
};

export const setLocalStorage = (key, value) => {
  if (!window.localStorage) {
    localStoragePolyFill();
  }

  if (typeof value === 'undefined') {
    window.localStorage.removeItem(key);
  } else {
    window.localStorage.setItem(key, value);
  }
};

export const getLocalStorage = key => {
  if (!window.localStorage) {
    localStoragePolyFill();
  }

  return window.localStorage.getItem(key);
};

/* istanbul ignore next */
export const expiredLocalStorage = () => {
  if (!window.localStorage) {
    localStoragePolyFill();
  }
  return {
    setLocalStorage: function (key, value, days) {
      // 设置过期原则
      if (!value) {
        window.localStorage.removeItem(key);
      } else {
        let expiriesTime = days || 7; // 默认保留7天
        let exp = new Date();
        window.localStorage.setItem(
          key,
          JSON.stringify({
            value,
            expires: exp.getTime() + expiriesTime * 24 * 60 * 60 * 1000,
          })
        );
      }
    },
    getLocalStorage: function (name) {
      const value = window.localStorage.getItem(name);
      if (value === undefined) {
        return null;
      }
      try {
        let o = JSON.parse(value);
        if (!o || o.expires < Date.now()) {
          if (o && o.expires < Date.now()) {
            window.localStorage.removeItem(name);
          }
          return null;
        } else {
          return o.value;
        }
      } catch (e) {
        console.log(e);
        return null;
      } finally {
      }
    },
  };
};

/**
 * usage like: clipboard(window, document, navigator).copy('your content');
 */
/* istanbul ignore next */
export const clipboard = function (window, document, navigator) {
  let textArea, copy;

  // 判断是不是ios端
  function isOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }
  //创建文本元素
  function createTextArea(text) {
    textArea = document.createElement("textArea");
    textArea.value = text;
    textArea.style.cssText = "position: fixed; ";
    document.body.insertBefore(textArea, document.body.firstChild);
    console.warn("createTextArea");
  }
  //选择内容
  function selectText() {
    let range, selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyDialog() {
    return (
      <div className="copy_dialog">
        <span>复制成功</span>
        <p>建议将网址发送至电脑浏览器进行打印</p>
      </div>
    );
  }

  //复制到剪贴板
  function copyToClipboard(createSoleStatus) {
    try {
      if (document.execCommand("Copy")) {
        createSoleStatus
          ? ToastManager.showToastText(copyDialog(), 5000)
          : ToastManager.showToastText("复制成功", 1500);
      } else {
        ToastManager.showToastText("复制错误,请手动复制", 2000);
      }
    } catch (err) {
      console.log(err);
      ToastManager.showToastText("复制错误,请手动复制", 2000);
    }
    document.body.removeChild(textArea);
  }

  // createSoleStatus 代表可定制的字段, 需要的话传递(true), 默认不传(false)
  copy = function (text, createSoleStatus = false) {
    createTextArea(text);
    selectText();
    copyToClipboard(createSoleStatus);
  };

  return {
    copy: copy,
  };
};

/* istanbul ignore next */
export const userReactRenderDetection = () => {
  if (isDevelopment()) {
    // import ('why-did-you-update').then(res=> {
    //   res(React);
    // })
  }
};

/* istanbul ignore next */
export const reloadPage = () => {
  let url = window.location.href;
  if (url.indexOf("?") == -1) {
    url += "?";
  } else {
    url += "&";
  }
  window.location.href = `${url}timestamp=${new Date().getTime()}`;
};

//usage like:  let mini_program_url = e.currentTarget.getAttribute("data-url")
//             onMiniProgram(e, mini_program_url)
/* istanbul ignore next */
export const onMiniProgram = (e, mini_url, params = {}, linkType) => {
  let paramsString = convertURLToJSON(mini_url, params);
  const url = `/pages/webView/webView?url=${paramsString}`;
  if (UserAgent.isMiniProgram()) {
    e && e.preventDefault();
    if (linkType === "redirect") {
      wx.ready(function () {
        wx.miniProgram.redirectTo({ url: url });
      });
      return;
    }
    wx.ready(function () {
      wx.miniProgram.navigateTo({ url: url });
    });
  }
};

/* istanbul ignore next */
export const onNavigate = (e, url, params = {}, linkType = "") => {
  if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
    wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        onMiniProgram(e, url, params, linkType);
      } else {
        window.location.href = url;
      }
    });
  } else if (UserAgent.isNativeClient()) {
    XinYaNativeBridge.navigate({
      href: url,
      target: params["target"],
      type: params["type"],
    });
  } else {
    window.location.href = url;
  }
};

/* istanbul ignore next */
export const isMiniProgram = () => {
  return;
};

/* istanbul ignore next */
export function convertURLToJSON(url, params = {}) {
  let searchParams = new URI(url).query();
  let pathname = new URI(url).path();
  let anchor = new URI(url).anchor();
  let parsedQuery = queryString.parse(searchParams);
  let parses = Object.assign(parsedQuery, params);
  let parameters = {
    path: pathname,
    queryParams: parses,
    anchor: anchor,
  };
  let paramsString = JSON.stringify(parameters);
  return paramsString;
}

/* istanbul ignore next */
export const promisifiedLoadImage = (imagesSrcArr) => {
  if (!Array.isArray(imagesSrcArr)) {
    throw new Error("promisifiedLoadImage: must pass Array instance!");
    return;
  }
  function promiseFactory(imageSrc, index) {
    let loadedImage = new Image();
    return new Promise((resolve, reject) => {
      loadedImage.onload = () => {
        // console.log(index)
        resolve(imageSrc);
      };
      loadedImage.onerror = () => {
        reject(imageSrc);
      };
      loadedImage.src = imageSrc;
    });
  }
  return Promise.all(
    imagesSrcArr.map((src, index) => {
      return promiseFactory(src, index);
    })
  );
};

/* istanbul ignore next */
export const throttle = function (fn, interval) {
  let _self = fn, // 保存需要被延迟执行的函数引用
    timer, // 定时器
    firstTime = true; // 是否是第一次调用
  return function () {
    const args = arguments,
      _me = this;
    if (firstTime) {
      // 如果是第一次调用，不需延迟执行
      _self.apply(_me, args);
      return (firstTime = false);
    }
    if (timer) {
      // 如果定时器还在，说明前一次延迟执行还没有完成
      return false;
    }
    timer = setTimeout(function () {
      // 延迟一段时间执行
      clearTimeout(timer);
      timer = null;
      _self.apply(_me, args);
    }, interval || 500);
  };
};

/* istanbul ignore next */
export const debounce = function (action, delay = 300, immediate = true) {
  let timer = null;
  return function debouncing() {
    const self = this;
    const callNow = immediate && !timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      if (!immediate) {
        action.apply(self, arguments);
      }
    }, delay);
    if (callNow) {
      action.apply(self, arguments);
    }
  };
};
// usage:
// let fetchUseRetry = retryFetch({times: 2, delay: 300});

// fetchUseRetry(XYRequest).then(function (data) {
//     console.log(data)
// }).catch(function (err) {
//     console.log(err);
// });
/* istanbul ignore next */
export const retryFetch = function (opts) {
  try {
    const DRYOptions = Object.assign(
      {
        times: 1,
        delay: 300,
      },
      opts
    );

    let originalFunc;
    return function exec(fn) {
      if (!originalFunc) originalFunc = fn;
      return fn().catch(function (error) {
        if (DRYOptions.times-- <= 0) return Promise.reject(error);
        return exec(delay);
      });
    };
    // 延时处理
    function delay() {
      return new Promise(function (resolve) {
        setTimeout(function () {
          console.log("retry");
          resolve(originalFunc());
        }, DRYOptions.delay);
      });
    }
  } catch (err) {
    console.log(err);
    Raven.captureException(err);
  }
};

/* istanbul ignore next */
export const throttledRequest = function (fn, delay, optionCallBack) {
  let timer = null;
  return function (times, limition) {
    console.log(times, limition);
    if (timer) {
      const dialogOptions = {
        text: "重试过于频繁，请稍等片刻",
        mainLabel: "确定",
        mainCallback: () => {
          optionCallBack();
        },
      };
      showDialog.call(this, dialogOptions);
      return timer;
    }
    if (times >= limition) {
      const dialogOptions = {
        text: "重试过于频繁，请稍等片刻",
        mainLabel: "确定",
        mainCallback: () => {
          optionCallBack();
        },
      };
      showDialog.call(this, dialogOptions);
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
      }, delay);
      return timer;
    } else {
      fn(times, limition);
      return;
    }
  };
};

/* istanbul ignore next */
export const showDialog = (options) => {
  const defaultOps = {
    text: "",
    mainLabel: "确定",
    mainCallback: () => {},
    subCallback: () => {},
  };
  options = Object.assign({}, defaultOps, options);
  let confirmDialog = "";
  let buttons = [
    {
      label: options.mainLabel,
      onClick: () => {
        confirmDialog && confirmDialog.close();
        options.mainCallback();
      },
    },
  ];
  if (options.mainLabel && options.subLabel) {
    buttons = [
      {
        label: options.mainLabel,
        onClick: () => {
          confirmDialog && confirmDialog.close();
          options.mainCallback();
        },
      },
      {
        label: options.subLabel,
        onClick: () => {
          confirmDialog && confirmDialog.close();
          options.subCallback();
        },
      },
    ];
  }

  confirmDialog = DialogManager.open(
    <Dialog buttons={buttons}>{options.text}</Dialog>
  );
};

/* istanbul ignore next */
export const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

/* istanbul ignore next */
export const logPageUnMount = function (logUrl) {
  const needToRemoveEvent = {};
  const _clearEventListener = function () {
    for (const [event, handler] of Object.entries(needToRemoveEvent)) {
      if (event) {
        window.removeEventListener(event, handler);
      }
    }
  };

  const _log = function (action) {
    console.warn(action);
    _clearEventListener();
    const image = new Image();
    console.warn(`${logUrl}?user_action=${action}`);
    image.src = `${logUrl}?user_action=${action}`;
    window.loggedPageUnMount = true;
  };

  const listenPageClose = function () {
    window.loggedPageUnMount = false;
    let beforeUnloadTime = 0;
    let gap_time = 0;

    // 刷新onbeforeunload-服务端请求数据-onunload，
    // 页面关闭onbeforeunload-onunload
    const onPageUnMount = function () {
      beforeUnloadTime = new Date().getTime();
    };
    const onPageUnMounted = function () {
      gap_time = new Date().getTime() - beforeUnloadTime;
      // console.warn(gap_time)
      if (
        sessionStorage.getItem("ListenJoinInPagePushStack") -
          window.prePushStack >
        0
      ) {
        if (!window.loggedPageUnMount) {
          // 页面回退未发送过
          // _log('page_refresh')
        }
        return;
      }
      if (gap_time <= 50 || beforeUnloadTime === 0) {
        if (!window.loggedPageUnMount) {
          // 页面回退未发送过
          _log("page_close");
        }
      } else {
        if (!window.loggedPageUnMount) {
          // 页面回退未发送过
          // _log('page_refresh' + gap_time)
        }
      }
    };
    // const eventName = UserAgent.isIOS() ? 'pagehide' : 'beforeunload'
    needToRemoveEvent["beforeunload"] = onPageUnMount;
    needToRemoveEvent["unload"] = onPageUnMounted;
    // window.addEventListener("beforeunload", onPageUnMount);
    // window.addEventListener("unload", onPageUnMounted);
  };

  const listenPageBack = function () {
    let pushStack = 0;
    window.loggedPageUnMount = false;
    const reloadUrl = function () {
      const prePushStack = sessionStorage.getItem("ListenJoinInPagePushStack");
      if (prePushStack !== 0) {
        pushStack = prePushStack;
        history.pushState("state", null, "#");
        pushStack++;
      } else {
        history.pushState("state", null, "#");
        pushStack++;
      }
      sessionStorage.setItem("ListenJoinInPagePushStack", pushStack);
    };
    const eventHandler = function () {
      if (event.state) {
        // 浏览器回退
        if (!window.loggedPageUnMount) {
          _log("page_back");
        }
        // console.warn('pageBack')
        history.go(`-${pushStack}`);
        sessionStorage.removeItem("ListenJoinInPagePushStack");
      }
    };
    const judgeBack = function () {
      reloadUrl();
      needToRemoveEvent["popstate"] = eventHandler;
      // window.addEventListener("popstate", eventHandler);
    };
    reloadUrl();
    judgeBack();
  };
  return {
    listenPageClose,
    listenPageBack,
  };
};

/* istanbul ignore next */
export const promptPageBack = (pageMark, alertText) => {
  const urlDetection = {
    pushStack: 0,

    reloadUrl: function () {
      const pushStack = sessionStorage.getItem(pageMark);
      if (pushStack !== 0) {
        this.pushStack = pushStack;
        history.pushState("state", null, "#");
        this.pushStack++;
      } else {
        history.pushState("state", null, "#");
        this.pushStack++;
      }
      sessionStorage.setItem(pageMark, this.pushStack);
    },

    eventHandler: function (e) {
      const self = this;
      console.log(e);
      let confirmDialog = "";
      if (e.state) {
        const buttons = [
          {
            label: "不退出",
            onClick: () => {
              confirmDialog && confirmDialog.close();
              history.pushState("state", null, "#");
            },
          },
          {
            label: "退出",
            onClick: () => {
              confirmDialog && confirmDialog.close();
              history.go(`-${self.pushStack}`);
              sessionStorage.removeItem(pageMark);
            },
          },
        ];
        confirmDialog = DialogManager.open(
          <Dialog buttons={buttons}>{`${alertText}`}</Dialog>
        );
      }
    },

    judgeBack: function () {
      this.reloadUrl();
      window.onpopstate = this.eventHandler.bind(this);
    },

    clearPrompt: function () {
      const pushStack = sessionStorage.getItem(pageMark);
      if (pushStack > 0) {
        history.go(`-${pushStack}`);
        sessionStorage.removeItem(pageMark);
      }
    },
  };

  return {
    setPrompt: () => {
      urlDetection.reloadUrl();
      urlDetection.judgeBack();
    },
    clear: urlDetection.clearPrompt,
  };
};

/* istanbul ignore next */
export const isEmpty = (value) => {
  let emptyObj = false;
  let emptyStr = false;
  if (value instanceof Object || value instanceof Array) {
    emptyObj = Object.keys(value).length === 0;
  }
  if (typeof value === "string") {
    emptyStr = value.trim() === "";
  }
  const nil = value == null;
  const nan = Number.isNaN(value);

  return emptyObj || emptyStr || nil || nan;
};

/* istanbul ignore next */
export const isNull = (value) => {
  return value == null || Number.isNaN(value);
};

/* istanbul ignore next */
export const print = (() => {
  if (isDevelopment()) {
    return console.log;
  }
  if (window && window.location.hash == "#test") {
    return console.log;
  }
  if (window && window.location.host == "test.xinya.me") {
    return console.log;
  }
  return () => {};
})();

/* istanbul ignore next */
export const tracking = (msg, event) => {
  if (!msg) {
    return;
  }
  const userId = msg["user_id"];
  const mediaLessonId = msg["media_lesson_id"];
  const ua = msg["ua"];
  const date = format(new Date(), "YYYY-MM-DD HH:mm:ss.SSS");
  const quizId = msg["quiz_id"];
  if (typeof ga === "function") {
    print("ga send event debug");
    ga(
      "send",
      "event",
      `Debug-${userId}`,
      `${mediaLessonId}-${quizId}-${event} || ${date}`,
      ua
    );
  }
};

// check the number is decimal number
/* istanbul ignore next */
export const checkDecimal = (number) => {
  if (!isNull(number)) {
    var reg = /^[0-9]*\.\d+$/;
    return reg.test(number);
  }
  return false;
};

/* istanbul ignore next */
export const generatePoster = (() => {
  const loadImages = (imagesSrcArr) => {
    console.log("745-imagesSrcArr: ", imagesSrcArr);
    if (!Array.isArray(imagesSrcArr)) {
      throw new Error("promisifiedLoadImage: must pass Array instance!");
    }
    function promiseFactory(imageSrc, index) {
      const loadedImage = new Image();
      loadedImage.setAttribute("crossOrigin", "Anonymous");
      return new Promise((resolve, reject) => {
        loadedImage.onload = () => {
          // console.log(index)
          resolve(loadedImage);
        };
        loadedImage.onerror = () => {
          reject(loadedImage);
        };
        loadedImage.src = imageSrc;
      });
    }
    return Promise.all(
      imagesSrcArr.map((src, index) => {
        if (src) {
          return promiseFactory(src, index);
        } else {
          return null;
        }
      })
    );
  };

  const makePoster = (images, qiuNiuToken, posterUniqueId, options) => {
    console.log("makePoster-images-options: ", images, options);
    return new Promise((resolve, reject) => {
      drawCanvas(images, options)
        .then((canvas) =>
          convertCanvasToImage(canvas, qiuNiuToken, posterUniqueId)
        )
        .then((imageBase64) => resolve([imageBase64, posterUniqueId]))
        .catch((err) => reject(err));
    });
  };

  const drawCanvas = (images, options) => {
    console.log("drawCanvas-options: ", options);
    console.log("drawCanvas-typeof options: ", typeof options);
    const template = images[0];
    const userPhoto = images[1];
    const userQrcode = images[2];
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      ctx.globalCompositeOperation = "source-atop";

      canvas.width = template.width;
      canvas.height = template.height;
      ctx.drawImage(template, 0, 0, canvas.width, canvas.height);
      ctx.save();

      if (typeof options == "object") {
        if (userPhoto) {
          const circleR = 64; // 半径
          const circleX = canvas.width / 2; // 圆心X坐标
          const circleY = 150; // 圆心Y坐标
          const imgX = circleX - circleR; // 图片X开始坐标
          const imgY = circleY - circleR; // 图片Y开始坐标
          const imgWidth = 2 * circleR; // 图片按圆形大小
          ctx.arc(circleX, circleY, circleR, 0, 2 * Math.PI);
          ctx.clip();
          ctx.drawImage(userPhoto, imgX, imgY, imgWidth, imgWidth);
          ctx.restore();
          ctx.save();
        }
      } else {
        if (options == "left") {
          if (userPhoto) {
            const circleR = 53; // 半径
            const circleX = 105 / 2 + 36; // 圆心X坐标
            const circleY = 105 / 2 + 21; // 圆心Y坐标
            const imgX = circleX - circleR; // 图片X开始坐标
            const imgY = circleY - circleR; // 图片Y开始坐标
            const imgWidth = 2 * circleR; // 图片按圆形大小
            ctx.arc(circleX, circleY, circleR, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(userPhoto, imgX, imgY, imgWidth, imgWidth);
            ctx.restore();
            ctx.save();
          }
        } else {
          if (userPhoto) {
            const circleR = 64; // 半径
            const circleX = canvas.width / 2; // 圆心X坐标
            const circleY = 150; // 圆心Y坐标
            const imgX = circleX - circleR; // 图片X开始坐标
            const imgY = circleY - circleR; // 图片Y开始坐标
            const imgWidth = 2 * circleR; // 图片按圆形大小
            ctx.arc(circleX, circleY, circleR, 0, 2 * Math.PI);
            ctx.clip();
            ctx.drawImage(userPhoto, imgX, imgY, imgWidth, imgWidth);
            ctx.restore();
            ctx.save();
          }
        }
      }

      if (userQrcode) {
        ctx.clearRect(
          options && options?.qrCodePosition ? options?.qrCodePosition?.x : 510,
          options && options?.qrCodePosition
            ? options?.qrCodePosition?.y
            : 1110,
          200,
          200
        );
        ctx.drawImage(
          userQrcode,
          options && options?.qrCodePosition ? options?.qrCodePosition?.x : 510,
          options && options?.qrCodePosition
            ? options?.qrCodePosition?.y
            : 1110,
          200,
          200
        );
        ctx.restore();
      }

      resolve(canvas);
    });
  };

  const uploadPoster = (b64, openPoster, failToOpenPoster, qiuNiuToken) => {
    const putb64 = (picBase, token) => {
      picBase = picBase.substring(22);
      function fileSize(str) {
        let fileSize;
        if (str.indexOf("=") > 0) {
          const indexOf = str.indexOf("=");
          str = str.substring(0, indexOf);
        }

        fileSize = parseInt(str.length - (str.length / 8) * 2);
        return fileSize;
      }

      const url = "https://upload.qiniup.com/putb64/" + fileSize(picBase);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
            const res = xhr.responseText;
            console.log(JSON.parse(res).url + "?imageView2/0/format/png");
            openPoster(JSON.parse(res).url + "?imageView2/0/format/png");
          } else {
            console.log(xhr.status, xhr.statusText);
            failToOpenPoster(xhr.status);
          }
        }
      };

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Content-Type", "image/png");
      xhr.setRequestHeader("Authorization", "UpToken " + token);
      xhr.send(picBase);
    };
    return putb64(b64, qiuNiuToken, openPoster, failToOpenPoster);
  };

  const convertCanvasToImage = (canvas, qiuNiuToken) => {
    return new Promise((resolve) => {
      const openPoster = (src) => {};
      const failToOpenPoster = (err) => {};
      // 非APP 直接用base64打开poster，并行上传海报
      const imageBase64 = canvas.toDataURL("image/png");
      uploadPoster(imageBase64, openPoster, failToOpenPoster, qiuNiuToken);
      resolve(imageBase64);
    });
  };

  // imagesArr sort template userPhoto qrcode
  return (imagesArr, qiuniuToken, posterUniqueId, options) => {
    console.log("903-imagesArr: ", imagesArr);
    console.log("904-qiuniuToken: ", qiuniuToken);
    console.log("905-posterUniqueId: ", posterUniqueId);
    console.log("906-options: ", options);
    return loadImages(imagesArr)
      .then((loadedImages) =>
        makePoster(loadedImages, qiuniuToken, posterUniqueId, options)
      )
      .catch((err) => Promise.reject(err));
  };
})();
