import React from "react";
import { print } from "../../utils/helpers";
import CountDownTime from "../../utils/CountDown";
import count_time_icon from "count_down_course/count_time_icon.png";
import ready_go_back from "count_down_course/ready_go_back.png";
import ready_go from "count_down_course/ready_go.gif";
import Raven from "raven-js";
import { request } from "../../utils/request";
export default class MediaLessonFillQuiz extends React.Component {
  constructor(props) {
    super(props);

    print(props);

    const record = {
      start_answer_time: null,
      end_answer_time: null,
      total_answer_count: 0,
    };

    this.state = {
      timeOutID: null,
      record: record,
      effectSoundURL: "",
      fillDisplay: "none",
      question_type: props.question_type,
      answer: props.answer,
      answerText: "",
      showEndtime: 0,
      // new Date().getTime() +
      // (Number(this.props.currentQuiz.timeout_value || 0) +
      //   (this.props.currentQuiz.is_first_multiple_race_quiz ? 0 : 6)) *
      //   1000,
      displayAlert: false,
      liked: true,
      showMuilResult: false,
      nextLessRecord: null,
      race_quizzes_result: null,
      hideReady: this.props.currentQuiz.is_first_multiple_race_quiz
        ? false
        : true, //多题回答
      isMuilFirst: this.props.currentQuiz.is_first_multiple_race_quiz
        ? true
        : false, //多题回答
      limitTimes: this.props.currentQuiz.timeout_value
        ? this.props.currentQuiz.timeout_value
        : 0, //限时
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    this.setState(
      {
        answerText: value,
      },
      () => {
        print(this.state.answerText);
      }
    );
  };

  setFills = () => {
    const record = {
      start_answer_time: null,
      end_answer_time: null,
      total_answer_count: 0,
    };
    this.setState({
      answerText: "",
      record: record,
    });
  };

  btnSubmitFillAnwer = (e) => {
    if (!e) {
      let record = this.state.record;
      print(record);

      let pre_answer_count = record.total_answer_count;
      print("点击答题记录：" + pre_answer_count);
      record["total_answer_count"] = pre_answer_count + 1;
      return new Promise((resolve) => {
        const timeOutID = setTimeout(() => {
          // this.delayExcute.call(this);
          resolve();
        }, 500);
        record["end_answer_time"] = new Date();
        this.setState({
          timeOutID: timeOutID,
          record: record,
        });
        // print("回答正确：" + this.state.answerText)
        this.props.operateSingleAnswerText.pushSingleAnswer("");
        this.props
          .submitFillRecord(this.state.record, "")
          .then((res) => {
            this.answered = false;
            if (res.data.race_quizzes_result) {
              console.log("000000000000000000000000000000000");
              this.setState({
                race_quizzes_result: res.data.race_quizzes_result,
                nextLessRecord: res.data.lesson_record,
                showMuilResult: true,

                answerText: "",
                total_answer_count: 0,
                liked: true,
              });
            } else {
              this.props.setLessonRecord(res.data.lesson_record);
              this.delayExcute();
              this.setState({
                fillDisplay: "none",
                answerText: "",
                total_answer_count: 0,
                liked: true,
              });
            }
          })
          .catch((e) => {});
      }).then(() => {
        this.setState({
          liked: true,
        });
      });
    } else {
      e.preventDefault();
      // print("填写答案： 进入")

      if (this.state.liked == false || this.answered) {
        return;
      }
      this.setState({
        liked: false,
      });

      // 网页端-app端输入填空题
      if (this.state.answerText.trim() === "") {
        alert("请输入答案");
        this.setState({
          liked: true,
        });
      } else {
        let record = this.state.record;
        print(record);

        let pre_answer_count = record.total_answer_count;
        print("点击答题记录：" + pre_answer_count);
        record["total_answer_count"] = pre_answer_count + 1;

        if (this.state.answerText.trim() === this.props.answer) {
          record["end_answer_time"] = new Date();
          this.props
            .promisifiedPlayEffectAudioWithReward(
              "effect_pass_sound",
              this.state.record
            )
            .then(() => {
              return new Promise((resolve) => {
                const timeOutID = setTimeout(() => {
                  // this.delayExcute.call(this);
                  resolve();
                }, 500);

                this.setState({
                  timeOutID: timeOutID,
                  record: record,
                });
                // print("回答正确：" + this.state.answerText)
                this.props.operateSingleAnswerText.pushSingleAnswer(
                  this.state.answerText
                );
                this.props
                  .submitFillRecord(this.state.record, this.state.answerText)
                  .then((res) => {
                    this.answered = false;
                    if (res.data.race_quizzes_result) {
                      console.log("000000000000000000000000000000000");
                      this.setState({
                        race_quizzes_result: res.data.race_quizzes_result,
                        nextLessRecord: res.data.lesson_record,
                        showMuilResult: true,

                        answerText: "",
                        total_answer_count: 0,
                        liked: true,
                      });
                    } else {
                      this.props.setLessonRecord(res.data.lesson_record);
                      this.delayExcute();
                      this.setState({
                        fillDisplay: "none",
                        answerText: "",
                        total_answer_count: 0,
                        liked: true,
                      });
                    }
                  })
                  .catch((e) => {});

                // this.setState({
                //   // fillDisplay: "none",
                //   answerText: "",
                //   total_answer_count: 0,
                // });
              }).then(() => {
                this.setState({
                  liked: true,
                });
              });
            })
            .catch((err) => {
              // print(err);
              console.log(
                "if (this.state.answerText.trim() === this.props.answer) {",
                err
              );
              Raven.captureException(JSON.stringify(err));
            });
        } else {
          if (record["total_answer_count"] < this.props.try_round_fill) {
            this.props
              .promisifiedPlayEffectAudioWithReward("effect_fail_human_sound")
              .then(() => {
                this.answered = false;
                this.props.operateSingleAnswerText.pushSingleAnswer(
                  this.state.answerText
                );
                return new Promise((resolve) => {
                  this.setState({
                    answerText: "",
                    fillDisplay: "block",
                    record: record,
                  });
                  setTimeout(() => {
                    resolve();
                  }, 2000);
                });
              })
              .then(() => {
                this.setState({
                  liked: true,
                });
              })
              .catch((err) => {
                // print(err)
                console.log("effect_fail_human_sound++++++++++", err);
                Raven.captureException(JSON.stringify(err));
              });
          } else if (
            record["total_answer_count"] == this.props.try_round_fill
          ) {
            record["end_answer_time"] = new Date();
            record["total_answer_count"] += 1;
            this.props
              .promisifiedPlayEffectAudioWithReward("effect_fail_sound")
              .then(() => {
                return new Promise((resolve) => {
                  const timeOutID = setTimeout(() => {
                    // this.delayExcute.call(this);
                    resolve();
                  }, 500);

                  // print("回答错误：" + this.state.answerText)
                  this.props.operateSingleAnswerText.pushSingleAnswer(
                    this.state.answerText
                  );
                  this.props
                    .submitFillRecord(this.state.record, this.state.answerText)
                    .then((res) => {
                      this.answered = false;
                      if (res.data.race_quizzes_result) {
                        this.setState({
                          race_quizzes_result: res.data.race_quizzes_result,
                          nextLessRecord: res.data.lesson_record,
                          showMuilResult: true,

                          timeOutID: timeOutID,
                          record: record,
                          answerText: "",
                          total_answer_count: 0,
                          liked: true,
                        });
                      } else {
                        this.props.setLessonRecord(res.data.lesson_record);
                        this.delayExcute();
                        this.setState({
                          fillDisplay: "none",
                          timeOutID: timeOutID,
                          record: record,
                          answerText: "",
                          total_answer_count: 0,
                          liked: true,
                        });
                      }
                    })
                    .catch((e) => {});
                  // this.setState({
                  //   fillDisplay: "none",
                  //   timeOutID: timeOutID,
                  //   record: record,
                  //   answerText: "",
                  //   total_answer_count: 0,
                  // });
                });
              })
              .then(() => {
                this.setState({
                  liked: true,
                });
              })
              .catch((err) => {
                // print(err)
                console.log("  liked: true,===", err);
                Raven.captureException(JSON.stringify(err));
              });
          }
        }
      }
    }
  };
  //获取该组件的倒计时结束时间
  changeTime(time) {
    this.setState({ showEndtime: time });
  }
  // 播放答题音效
  promisifiedPlayEffectAudio = (ElementId) => {
    let audio = document.getElementById(ElementId);
    console.log(audio.src, "promisifiedPlayEffectAudio111");
    // let times = 0;
    // return request({ url: `${audio.src}?avinfo`, method: "GET" }).then(
    //   (res) => {
    //     times += Math.ceil(Number(res.data.format.duration) * 1000);
    //     console.log(times, "res------------times", res.data.format.duration);
    print(
      "===========promisifiedPlayEffectAudio===========" +
        this.props.recordTimes[ElementId]
    );
    return new Promise((resolve, reject) => {
      audio.play();
      setTimeout(() => {
        resolve();
      }, this.props.recordTimes[ElementId] || 2000);
    });
    //   }
    // );
  };
  delayExcute() {
    this.hiddenFillAnswer();
    this.props.playAud();
    if (this.state.timeOutID) {
      clearTimeout(this.state.timeOutID);
    }
  }

  showFillAnswer() {
    let record = this.state.record;
    record["start_answer_time"] = new Date();
    this.setState({
      fillDisplay: "block",
      record,
      isShowHide: "block",
    });
  }
  handleCountdownEnd() {
    console.log(this, "填空题this");
    if (this.answered) {
      return;
    }
    this.answered = true;
    this.setState({
      showEndtime: 0,
    });
    this.btnSubmitFillAnwer(false);
  }
  hiddenFillAnswer() {
    this.setState({
      fillDisplay: "none",
      isShowHide: "none",
    });
  }

  render() {
    let { isReplaying } = this.props;
    return this.state.fillDisplay == "block" ? (
      <div className="lesson_fill_subject_box">
        <div
          className="lesson_fill_subject"
          style={{
            display: this.state.fillDisplay,
          }}
        >
          {/* <div className="ready-go"> */}
          {/* <div className="ready_go_count">
              <div className="animal-style">
                <div className="animal-style-2"></div>
              </div>
            </div> */}
          {/* <img src={ready_go_count}></img> */}
          {/* <audio
              src="https://upload.fireflybaby.cn/race/start_race.mp3"
              autoPlay
            ></audio>
          </div> */}
          {/* <div className="ready-go">
            <audio
              src="https://upload.fireflybaby.cn/race/start_race.mp3"
              autoPlay
            ></audio>
            <div className="icon_back">
            <img src={ready_go_back}></img>
            <img src={ready_go}></img>
            </div>
          </div> */}

          {this.state.isMuilFirst &&
          !this.state.hideReady &&
          this.state.fillDisplay == "block" ? (
            <CountDownTime
              key={this.props.currentQuiz.id + "readygo"}
              endTime={new Date(new Date().getTime() + 5 * 1000)}
              overText="ready go!"
              onEnd={() => {
                this.setState({
                  hideReady: true,
                  showEndtime:
                    new Date().getTime() + (this.state.limitTimes + 1) * 1000,
                });
              }}
            >
              {({ d, h, m, s }) => {
                return (
                  <div className="ready-go">
                    <audio
                      src="https://upload.fireflybaby.cn/race/start_race.mp3"
                      autoPlay
                    ></audio>
                    <img src={ready_go_back}></img>
                    <img src={ready_go + "?" + this.props.currentQuiz.id}></img>
                    {/* <div className="icon_back">
                      <img src={ready_go}></img>
                    </div> */}
                  </div>
                );
              }}
            </CountDownTime>
          ) : (
            ""
          )}
          {this.state.hideReady ? (
            this.state.showMuilResult ? (
              <CountDownTime
                key={this.props.currentQuiz.id + "result"}
                endTime={new Date(new Date().getTime() + 7 * 1000)}
                overText="继续学习!"
                onEnd={() => {
                  this.setState({ showMuilResult: false }, () => {
                    this.props.setLessonRecord(this.state.nextLessRecord);
                    this.delayExcute();
                  });
                }}
              >
                {({ d, h, m, s }) => {
                  return (
                    <div className="muil-result">
                      <div
                        className={`muil-result-center ${
                          this.state.race_quizzes_result.correct_count /
                            this.state.race_quizzes_result.total_count >=
                          0.75
                            ? "multiple-pass"
                            : " multiple-fail"
                        } `}
                      >
                        <div className="muil-result-back">
                          <div className="muil-result-quiz-count">
                            <span className="top">
                              <span className="top-count">
                                {this.state.race_quizzes_result.correct_count ||
                                  0}
                              </span>
                              题
                            </span>
                            <span>答对</span>
                          </div>
                          <div className="muil-result-quiz-time muil-result-quiz-count">
                            <span className="top">
                              <span className="top-count">
                                {this.state.race_quizzes_result.time || 0}
                              </span>
                              秒
                            </span>
                            <span>用时</span>
                          </div>
                          <div className="muil-result-quiz-moer muil-result-quiz-time muil-result-quiz-count">
                            <span className="top">
                              <span className="top-count">{`${
                                this.state.race_quizzes_result.percent || 0
                              }\%`}</span>
                              的小朋友
                            </span>
                            <span>超过</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </CountDownTime>
            ) : (
              <>
                {this.state.limitTimes ? (
                  <div className="count-time">
                    <CountDownTime
                      key={this.props.currentQuiz.id + "countTime"}
                      bindTime={this.changeTime.bind(this)}
                      endTime={
                        new Date(
                          this.state.showEndtime ||
                            new Date().getTime() +
                              (Number(this.state.limitTimes) + 1) * 1000
                        )
                      }
                      overText="答题结束"
                      onEnd={this.handleCountdownEnd.bind(this)}
                    >
                      {({ d, h, m, s }) => {
                        return (
                          <span className="timer">
                            <audio
                              src="https://upload.fireflybaby.cn/race/count_down.mp3"
                              loop="loop"
                              autoPlay
                            ></audio>
                            <img src={count_time_icon}></img>
                            {/* <span>{h}</span>: */}
                            <span>{m}</span>:<span>{s}</span>
                          </span>
                        );
                      }}
                    </CountDownTime>
                  </div>
                ) : (
                  ""
                )}

                <div className="fill_container">
                  <div className="fill_box">
                    <form action="#" onSubmit={this.btnSubmitFillAnwer}>
                      <input
                        ref={(ref) => (this.inputRef = ref)}
                        type="tel"
                        placeholder="请输入答案"
                        value={this.state.answerText}
                        onChange={this.handleInputChange}
                      />
                      <button type="submit">确定</button>
                    </form>
                  </div>

                  {!this.state.limitTimes && (
                    <div
                      className={
                        isReplaying ? "read_voice active " : "read_voice"
                      }
                      onClick={this.props.replayAud}
                    >
                      {this.props.replayText ?? "重听"}
                    </div>
                  )}
                </div>
              </>
            )
          ) : (
            ""
          )}
          {/* <div className="fill_container">
            <div className="fill_box">
              <form action="#" onSubmit={this.btnSubmitFillAnwer}>
                <input
                  ref={(ref) => (this.inputRef = ref)}
                  type="tel"
                  placeholder="请输入答案"
                  value={this.state.answerText}
                  onChange={this.handleInputChange}
                />
                <button type="submit">确定</button>
              </form>
            </div>
            <div
              className={isReplaying ? "read_voice active " : "read_voice"}
              onClick={this.props.replayAud}
            >
              重听
            </div>
          </div> */}
        </div>
      </div>
    ) : (
      ""
    );
  }
}
