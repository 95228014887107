// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/media_lesson_activity/icon_rate.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".rate-back{position:fixed;top:0;left:0;width:100%;height:100%;z-index:888;background:transparent}.rate-back .rate-lists{position:absolute;background:rgba(0,0,0,.5);right:4px;top:55px;border-radius:5px}.rate-back .rate-lists:before{content:\"\";position:absolute;top:-8px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);width:13px;height:8px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0/100% 100% no-repeat}.rate-back .lists-item{margin:3px 5px;padding:13px 22px;font-size:14px;font-family:San Francisco;font-weight:400;line-height:16px;color:#fff}.rate-back .selected-rate{background:rgba(0,0,0,.3);border-radius:5px}@media screen and (orientation:portrait)and (max-width:1280px){.rate-back .rate-lists{top:95px}}", ""]);
// Exports
module.exports = exports;
