import React from "react";
import PlainRecorder from "./recorder/PlainRecorder";
import SOERecorder from "./recorder/SOERecorder";

export default class QuizRecorder extends React.Component {
  componentDidMount() {
    if (this.props.showRePlaying) {
      $("#start_record").trigger("click");
      console.log("==");
    }
  }
  audoComplete() {
    this.props.effectTime();
    console.log("complete_record=================完成");
    $("#on_recording").trigger("click");
    setTimeout(() => {
      $("#complete_record").trigger("click");
    }, 300);
  }
  render() {
    return this.props.quiz.question_type === 2 ? (
      <SOERecorder
        ref={(recorderRef) => (this.recorderRef = recorderRef)}
        {...this.props}
      />
    ) : (
      <PlainRecorder
        ref={(recorderRef) => (this.recorderRef = recorderRef)}
        {...this.props}
      />
    );
  }
}
