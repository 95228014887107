import React from "react";
import styled from "styled-components";
import "./Popups.scss";
import icon_module_border from "c4/icon_module_border.png";
import icon_progress_pass from "../../../../../assets/images/c4/icon_progress_pass.png";
import back_icon from "practice_courses/back_icon.png";
import icon_zan from "c4/icon_zan.png";
import add_practice_icon from "c4/add_practice_icon.png";
import icon_pass from "c4/icon_pass.gif";
import icon_fail from "c4/icon_fail.gif";
import course_right_white from "../../../../../assets/images/course_right_white.png";
import icon_no_work from "c4/icon_no_work.gif";
import icon_waring from "c4/icon_waring.png";
import RenderButtom from "./RenderButtom";
// import icon_lock from "c4/icon_lock.png";
const Item = styled.div`
  background: ${({ pass }) => (pass ? "#FFCE32" : "#ffffff")};
  border: 1px solid ${({ pass }) => (pass ? "#fff" : "#eaeaea")};
  &::before {
    content: "";
    display: ${({ beforeDisplay }) => beforeDisplay};
    width: 26px;
    border-top: 1px solid ${({ pass }) => (pass ? "#ffce32" : "#EAEAEA")};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
  }
  &::after {
    content: "";
    display: ${({ afterDisplay }) => afterDisplay};
    width: 26px;
    border-top: 1px solid ${({ pass }) => (pass ? "#ffce32" : "#EAEAEA")};
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(100%, -50%);
  }
`;
const titles = [
  "哇~你真厉害!", //0
  "哇！你基础掌握得真好!", //1
  "不错哦！只有几题不熟~", //2
  "好啦，新课学习基础已补足!", //3
  "练习辛苦啦~ 发现一个之前的漏洞哦~", //4
  "练习辛苦啦~ 发现一个之前的漏洞哦~", //5
  "是不是学得有点吃力？", //6
  "哇，课程学得不错！", //7
  "哇~你真厉害~一次就通过了!", //8
  "哇~你真厉害~一次就通过了!", //9
  "今天的学习完成啦!", //10
  "哇~今天的练习完成啦!", //11
  "练习辛苦啦~", //12
  "分步练习完成啦！",
];
export default class Modules extends React.Component {
  constructor(props) {
    super(props);
    this.Popresize = () => {
      console.log($(window).width(), $(window).height());

      this.setState({
        width: $(window).width(),
        height: $(window).height(),
      });
    };
    this.Popresize.bind(this);
    // window.document.body.clientWidth>window.document.body.clientHeight
    this.state = {
      width: $(window).width(),
      height: $(window).height(),
    };
    console.log(this.state, "Modules-props", props);
  }
  componentDidMount() {
    this.screenChange2();
  }
  screenChange2() {
    window.addEventListener("resize", this.Popresize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.Popresize);
  }
  renderTitle(i) {
    let index = 0;
    switch (i) {
      case "8_5_pass":
      case "9_5_fail":
        index = 0;
        break;
      case "11_pass":
      case "1_pass":
        index = 1;
        break;
      case "2_fail":
        index = 2;
        break;
      case "3_pass":
        index = 3;
        break;
      case "3_5_pass":
        index = 3;
        break;
      case "6_fail":
        index = 6;
        break;
      case "7_pass":
      case "17_pass":
      case "19_pass":
      case "20_pass":
        index = 7;
        break;
      case "13_pass":
      case "8_pass":
        index = 8;
        break;
      case "9_pass":
        index = 9;
        break;
      case "10_5_fail":
      case "10_fail":
        index = 10;
        break;
      case "14_fail":
        index = 11;
        break;
      case "16_fail":
      case "15_proceed":
      case "15_proceed_pass":
        index = 12;
        break;
      case "18_proceed":
        index = 13;
        break;
      default:
        index = 0;
        break;
    }
    return !(
      String(i) == "4_fail" ||
      String(i) == "12_fail" ||
      String(i) == "5_fail"
    ) ? (
      titles[index]
    ) : (
      <>
        <div>练习辛苦啦~</div>
        <div>发现一个之前的漏洞哦~</div>
      </>
    );
  }
  renderContent(state, study_type) {
    const {
      media_lesson_name,
      star_page,
      end_time,
      is_has_media_lesson,
    } = this.props;
    let media_lesson_name_string = media_lesson_name ? media_lesson_name : "";
    // if (media_lesson_names && media_lesson_names.length > 0) {
    //   media_lesson_names.map((i, d) => {
    //     if (d == 0) {
    //       media_lesson_name_string = media_lesson_name_string + i.name;
    //     } else {
    //       media_lesson_name_string = media_lesson_name_string + "、" + i.name;
    //     }
    //   });
    // }
    let Content = "";
    switch (state) {
      case "9_5_pass":
      case "8_5_pass":
        Content = <div className="new-study">一次就通过了!</div>;
        break;
      case "19_pass":
        Content = <div className="new-study">继续下一课的学习吧!</div>;
        break;
      case "20_pass":
        Content = <div className="new-study">恭喜你完成全部课程学习!</div>;
        break;
      case "1_pass":
      case "11_pass":
        // 1
        Content = (
          <div className="pass">
            一次过关!
            <img src={icon_zan}></img>
          </div>
        );
        break;
      case "2_fail":
        //2
        Content = (
          <div className="add-practice">
            <div>我们再巩固下，不超过10分钟~</div>
            {media_lesson_name_string ? (
              <div className="add-practice-name">
                <div className={"add_practice_icon"}>补练</div>
                <div className="add-course-lists">
                  <div
                    className={
                      is_has_media_lesson ? "" : "add-course-lists-content"
                    }
                  >
                    {media_lesson_name_string}
                  </div>
                  {/* <div>
                  <div className="add">赠</div>
                </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "3_pass":
      case "16_fail":
        //3
        Content = <div className="new-study">开始新知识的学习吧!</div>;
        break;
      case "17_pass":
        // 3.5
        Content = (
          <div className="new-study">再来做几道分步练习题巩固一下吧!</div>
        );
        break;
      case "18_proceed":
        // 3.5再来挑战几道测试题吧！
        Content = <div className="new-study">再来挑战几道测试题吧！</div>;
        break;
      case "3.5_pass":
        // 3.5
        Content = <div className="new-study">下次课开始新知识的学习吧!!</div>;
        break;
      case "12_fail":
      case "4_fail":
        // 4
        Content = (
          <div className="new-study">
            <div>开个小灶，补上它吧~</div>
            {media_lesson_name_string ? (
              <div className="  add-practice-name">
                <div className={"add_practice_icon"}>小灶课</div>
                <div className="add-course-lists">
                  <div
                    className={
                      is_has_media_lesson ? "" : "add-course-lists-content"
                    }
                  >
                    {media_lesson_name_string}
                  </div>
                  {/* <div>
                  <div className="add">赠</div>
                </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "5_fail":
        //5
        Content = (
          <div className="new-study">
            <div>下次我们开个小灶，补上它吧~~</div>
            {media_lesson_name_string ? (
              <div className="  add-practice-name">
                <div className={"add_practice_icon"}>小灶课</div>
                <div className="add-course-lists">
                  <div
                    className={
                      is_has_media_lesson ? "" : "add-course-lists-content"
                    }
                  >
                    {media_lesson_name_string}
                  </div>
                  {/* <div>
                  <div className="add">赠</div>
                </div> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "6_fail":
        Content = (
          <div className="new-study">
            <div style={{ paddingLeft: 2 }}>让爸爸妈妈联系老师，</div>
            <div>调整一下课程吧~</div>
          </div>
        );
        break;
      case "7_pass":
        // 8
        Content = <div className="new-study">再来做几道题巩固一下吧!</div>;
        break;
      case "13_pass":
      case "8_pass":
        //9
        Content = (
          <div className="new-study">
            <div>
              获得了
              <span style={{ color: "#FFAA2B", fontWeight: "bold" }}>
                “免写作业”
              </span>
              特权哦~
            </div>

            {this.props.offline_study_pages ? (
              <div className="  add-practice-name-no">
                <div className={"add_practice_icon"}>作业</div>
                <p>
                  {`${study_type === 6 ? "《速度训练册》" : "《课后练习册》"}`}
                  <span
                    style={{ color: "#5AC2F9", fontWeight: "bold" }}
                  >{`${this.props.offline_study_pages}`}</span>
                </p>
                {/* {(this.props.start_page || this.props.end_page) && (
                  <>
                    {this.props.start_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.start_page}页`}</span>
                    ) : (
                      ""
                    )}
                    {this.props.start_page && this.props.end_page ? (
                      <span>~</span>
                    ) : (
                      ""
                    )}
                    {this.props.end_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.end_page}页`}</span>
                    ) : (
                      ""
                    )}
                  </>
                )} */}
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "14_fail":
      case "9_pass":
        //10
        Content = (
          <div className="new-study">
            <div>课后作业不要忘了做哦~</div>
            {this.props.offline_study_pages ? (
              <div className="  add-practice-name-no">
                <div className={"add_practice_icon"}>作业</div>
                <p>
                  {`${study_type === 6 ? "《速度训练册》" : "《课后练习册》"}`}
                  <span
                    style={{ color: "#5AC2F9", fontWeight: "bold" }}
                  >{`${this.props.offline_study_pages}`}</span>
                </p>
                {/* {(this.props.start_page || this.props.end_page) && (
                  <>
                    {this.props.start_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.start_page}页`}</span>
                    ) : (
                      ""
                    )}
                    {this.props.start_page && this.props.end_page ? (
                      <span>~</span>
                    ) : (
                      ""
                    )}
                    {this.props.end_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.end_page}页`}</span>
                    ) : (
                      ""
                    )}
                  </>
                )} */}
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "10_5_fail":
      case "10_fail":
        Content = (
          <div className="new-study">
            <div>但还没有完全掌握，</div>
            <div>明天来复习一下吧~</div>
            {this.props.offline_study_pages ? (
              <div className="  add-practice-name-no">
                <div className={"add_practice_icon"}>作业</div>
                <p>
                  {`《课后练习册》`}
                  <span
                    style={{ color: "#5AC2F9", fontWeight: "bold" }}
                  >{`${this.props.offline_study_pages}`}</span>
                </p>
                {/* {(this.props.start_page || this.props.end_page) && (
                  <>
                    {this.props.start_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.start_page}页`}</span>
                    ) : (
                      ""
                    )}
                    {this.props.start_page && this.props.end_page ? (
                      <span>~</span>
                    ) : (
                      ""
                    )}
                    {this.props.end_page ? (
                      <span
                        style={{ color: "#5AC2F9", fontWeight: "bold" }}
                      >{`第${this.props.end_page}页`}</span>
                    ) : (
                      ""
                    )}
                  </>
                )} */}
              </div>
            ) : (
              ""
            )}
          </div>
        );
        break;
      //新增待定
      case "15_proceed":
      case "15_proceed_pass":
        Content = (
          <div className="new-study ">
            <div className="limit-time">再来点高难度的挑战吧！</div>
            <div className="limit-time-desc">
              <img src={icon_waring} style={{ width: 16, height: 16 }}></img>
              下个环节每题都增加了<span>时间限制</span>哦
            </div>
          </div>
        );
        break;
      default:
        Content = (
          <div className="pass">
            一次过关
            <img src={icon_zan}></img>
          </div>
        );
        break;
    }
    //1
    // let Content = (
    //   <div className="pass">
    //     你真棒!
    //     <img src={icon_zan}></img>
    //   </div>
    // );
    //2
    // let Content = (
    //   <div className="add-practice">
    //     <div>我们再巩固下，不超过10分钟~</div>
    //     <div className="add-practice-name">
    //       <div className={"add_practice_icon"}>补练</div>
    //       倍数概念、100以内连加
    //     </div>
    //   </div>
    // );
    //3
    // let Content = <div className="new-study">开始新知识的学习吧!</div>;
    //4
    // let Content = <div className="new-study">下次课开始新知识的学习吧!</div>;

    // 5
    // let Content = (
    //   <div className="new-study">
    //     <div>开个小灶，补上它吧~</div>
    //     <div className="  add-practice-name">
    //       <div className={"add_practice_icon"}>小灶课</div>
    //       倍数概念、100以内连加
    //     </div>
    //   </div>
    // );
    //6
    // let Content = (
    //   <div className="new-study">
    //     <div>下次我们开个小灶，补上它吧~~</div>
    //     <div className="  add-practice-name">
    //       <div className={"add_practice_icon"}>小灶课</div>
    //       倍数概念、100以内连加
    //     </div>
    //   </div>
    // );
    //7
    // let Content = (
    //   <div className="new-study">
    //     <div>让爸爸妈妈联系老师，</div>
    //     <div>调整一下课程吧~</div>
    //   </div>
    // );
    //8
    // let Content = <div className="new-study">再来做几道题巩固一下吧!</div>;
    //9
    // let Content = (
    //   <div className="new-study">
    //     <div>
    //       获得了
    //       <span style={{ color: "#FFAA2B", fontWeight: "bold" }}>
    //         “免写作业”
    //       </span>
    //       特权哦~
    //     </div>

    //     <div className="  add-practice-name-no">
    //       <div className={"add_practice_icon"}>作业</div>
    //       {`练习册`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //       {`~`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //     </div>
    //   </div>
    // );
    //10
    // let Content = (
    //   <div className="new-study">
    //     <div>课后作业不要忘了做哦~</div>
    //     <div className="  add-practice-name-no">
    //       <div className={"add_practice_icon"}>作业</div>
    //       {`练习册`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //       {`~`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //     </div>
    //   </div>
    // );
    //11
    // let Content = (
    //   <div className="new-study">
    //     <div>但还没有完全掌握，</div>
    //     <div>明天来复习一下吧~</div>
    //     <div className="  add-practice-name-no">
    //       <div className={"add_practice_icon"}>作业</div>
    //       {`练习册`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //       {`~`}
    //       <span
    //         style={{ color: "#5AC2F9", fontWeight: "bold" }}
    //       >{`第${1}页`}</span>
    //     </div>
    //   </div>
    // );
    return Content;
  }
  // componentDidCatch
  render() {
    const {
      state,
      study_type,
      day_study_contents,
      media_lesson_names,
      next_step_title,
      next_url,
    } = this.props;
    return (
      <div
        className="course-modules-back"
        key={this.props.next_url ? this.props.next_url : this.props.state}
      >
        <div
          className=" back-fixed"
          style={{
            width:
              this.state.width > this.state.height && this.state.height < 550
                ? this.state.height
                : "",
            height:
              this.state.width > this.state.height && this.state.height < 550
                ? this.state.width
                : "",
            transform:
              this.state.width > this.state.height && this.state.height < 550
                ? `rotate(-90deg)  translate(${
                    (this.state.width - this.state.height) / 2
                  }px, ${(this.state.width - this.state.height) / 2}px)`
                : "",
          }}
        >
          <div className="no-pass-back">
            <img
              src={
                state == "8_pass"
                  ? icon_no_work
                  : state == "2_fail" ||
                    state == "4_fail" ||
                    state == "12_fail" ||
                    state == "5_fail" ||
                    state == "6_fail"
                  ? icon_fail
                  : icon_pass
              }
              className="back-icon"
            ></img>
            <div className="no-pass-tiitle">
              <div>{this.renderTitle(state)}</div>
              {/* <div>{`课程老师，更换/取消课程吧~`}</div> */}
            </div>
            <div className="no-pass-content">
              <img
                src={icon_module_border}
                className="icon_module_border"
              ></img>
              <div className="no-pass-content-small"></div>
              <div className="no-pass-content-big">
                <div className="content-flex">
                  {this.renderContent(state, study_type)}
                </div>
                {/* <img src={icon_module_border} className="code-img"></img> */}
              </div>
            </div>

            <div className="module-button">
              {day_study_contents.map((tm, ix) => {
                return (
                  <div className="item-style" key={tm.id + "o" + ix}>
                    <Item
                      className=" item"
                      pass={tm.pass}
                      beforeDisplay={ix == 0 ? "none" : "block"}
                      afterDisplay={
                        ix == day_study_contents.length - 1 ? "none" : "block"
                      }
                    >
                      {tm.pass && (
                        <img
                          src={icon_progress_pass}
                          className="icon_progress_pass"
                        ></img>
                      )}
                    </Item>
                    <div
                      style={{
                        width: day_study_contents.length > 1 ? 49 : "",
                        textAlign: "center",
                        height: 19,
                      }}
                    >
                      {tm.name && tm.name.length > 4 ? (
                        <>
                          <div style={{ lineHeight: "13px" }}>
                            {tm.name.substr(0, 3)}
                          </div>
                          <div style={{ lineHeight: "13px" }}>
                            {tm.name.substr(3)}
                          </div>
                        </>
                      ) : (
                        tm.name
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="go_next">
              <RenderButtom
                handleClick={() => {
                  window.location.href = next_url;
                }}
                text={
                  this.props.state === "20_pass"
                    ? "确定"
                    : this.props.state === "19_pass"
                    ? `下一课：${next_step_title}`
                    : `下一步：${next_step_title}`
                }
                answer={true}
                icon_img={
                  this.props.state === "20_pass" ? "" : course_right_white
                }
              ></RenderButtom>
            </div>
          </div>
        </div>
        <audio
          src={`https://upload.fireflybaby.cn/intelligent/practice_state_${
            state == "11_pass"
              ? "1_pass"
              : state == "12_fail"
              ? "4_fail"
              : state == "10_5_fail"
              ? "10_fail"
              : state == "13_pass"
              ? "8_pass"
              : state
          }.mp3`}
          autoPlay
        ></audio>
      </div>
    );
  }
}
