import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AppDownloadTip.scss";

class AppDownloadTip extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    return (
      <div className="app_download_tip_wrap">
        <div className="app_download_tip_box">
          <div className="app_download_tip_text">
            <h5>当前环境不支持录音功能，请安装App学习</h5>
            <p>继续学习将无法跟读录音</p>
          </div>
          <div className="app_download_tip_btn">
            <a href="https://a.app.qq.com/dom/micro/open.jsp?pkgname=cn.fireflykids.app">
              安装
            </a>
          </div>
        </div>
      </div>
    );
  }
}

AppDownloadTip.propTypes = {};

export default AppDownloadTip;
