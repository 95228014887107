import React from "react";
import styled from "styled-components";
import { ToastManager } from "../utils/Toast";
// import download_img from "../../../assets/images/download_img.png";
import Crystal from "point_rewards/crystal.png";
import "../shared/useful_css.scss";
import icon_correct from "c4/icon_correct.png";
// import icon_photo_check from "c4/icon_photo_check.png";
import no_data_icon from "c4/no_data_icon.png";
import "./CheckWrong.scss";
import { request, csrfHeaders } from "../utils/request";
import FullScreenCrystalReward from "../point_reward/FullScreenCrystalReward";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
import HomeworkAllCorrectIcon from "point_rewards/homework-all-correct.png";
const WrongBook = styled.div`
  padding: 20px 15px 40px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(90, 194, 249, 0.3);
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
`;
export default class CheckWrong extends React.Component {
  constructor(props) {
    super(props);
    let newArr = [];
    let wrongIds = [];
    if (props.quizzes.quiz_info.length > 0) {
      props.quizzes.quiz_info.map((itm, idx) => {
        if (itm.is_wrong_quiz) {
          wrongIds.push(itm.id);
        }
        newArr.push({ ...itm, wrong: itm.is_wrong_quiz });
      });
    }

    this.popupVariables = [];
    this.popupVariableIndex = 0;

    console.log("CheckWrong_props", props);
    this.state = {
      wrongIds: [...wrongIds],
      wrongs: newArr.length > 0 ? newArr : null,
      showFinishWholeDayStudyReward: false,
      showOfflineExamAllCorrect: false,
      currentPopupWindow: '',
      totalPoint: this.props.baby_point,
    };
  }


  addQuestionBankRewardComponent(showAddReward) {
    if (!showAddReward || !this.props.should_reward) {
      return "已加入错题本哦";
    } else {
      return (
        <div className="flex justify-center items-center">
          <div>已加入错题本哦, +1</div>
          <img
            src={Crystal}
            style={{ width: "26px", height: "26px", marginLeft: "8px" }}
          />
        </div>
      );
    }
  }

  allCorrectComponent = () => {
    return (
      <div className="flex items-center justify-center">
        <div>全对&nbsp;</div>
        {this.props.should_reward && (
          <div>+{this.props.all_correct_reward_offline_point}</div>
        )}
        {this.props.should_reward ? (
          <img src={Crystal} style={{ width: "22px", height: "22px" }} />
        ) : (
          ""
          // <div>，返回课程</div>
        )}
        {/* <div>，返回课程</div> */}
      </div>
    );
  };

  handleClick(index) {
    let { media_lesson_id, study_package_activity_id, quizzes } = this.props;
    let { wrongs } = this.state;
    let wrong = wrongs[index].wrong;
    let id = wrongs[index].id;
    wrongs[index].wrong = !wrong;
    let showAddReward = false;
    if (!wrongs[index].has_add_wrong_quiz_record) {
      wrongs[index].has_add_wrong_quiz_record = true;
      showAddReward = true;
    }

    this.setState(
      {
        wrongs: [...wrongs],
      },
      () => {
        if (!wrong) {
          ToastManager.showToastText(
            this.addQuestionBankRewardComponent(showAddReward),
            2000
          );
        }
        request({
          url: quizzes.add_wrong_quizzes_url,
          method: "POST",
          data: {
            media_lesson_id,
            study_package_activity_id,
            is_wrong_quiz: !wrong,
            quiz_ids: [id],
          },
          headers: csrfHeaders,
        });
        this.getWrongId();
      }
    );
  }

  getWrongId() {
    let { wrongs } = this.state;
    let newWrongIds = [];
    wrongs.map((it, idx) => {
      if (it.wrong) {
        newWrongIds.push(it.id);
      }
    });
    this.setState({
      wrongIds: newWrongIds,
    });
  }

  processResponse (res) {
    console.log(res)
    if (res.data.data) {
      this.popupVariables.push(
        { key: 'showOfflineExamAllCorrect', val: res.data.data.show_offline_exam_all_correct, point: this.props.all_correct_reward_offline_point },
        { key: 'showFinishWholeDayStudyReward', val: res.data.data.show_finish_all_day_lesson_reward, point: this.props.finish_whole_day_study_reward_point }
      )
    }
    this.handleFullScreenRewardConfirmClick()
  }

  handleFullScreenRewardConfirmClick() {
    console.log(this.popupVariables, this.popupVariableIndex)
    for (let i = this.popupVariableIndex; i < this.popupVariables.length; i++) {
      if (this.popupVariables[i].val) {
        const tmp = {}
        tmp.currentPopupWindow = this.popupVariables[i].key
        tmp.totalPoint = this.state.totalPoint + this.popupVariables[i].point
        this.setState(tmp)
        i += 1
        this.popupVariableIndex = i
        return
      }
    }
    this.defaultFinishOfflineExamCheckOperation()
  }

  defaultFinishOfflineExamCheckOperation() {
    if (this.state.wrongIds.length > 0) {
      window.location.href = this.props.get_wrong_quiz_sets_url;
    } else {
      window.location.href = this.props.details_url;
    }
  }

  render() {
    return this.props.is_buy_order ? (
      <div className="check-wrong-back">
        {this.state.currentPopupWindow === "showFinishWholeDayStudyReward" && (
          <FullScreenCrystalReward
            point={this.props.finish_whole_day_study_reward_point}
            totalPoint={this.state.totalPoint}
            textUrl={FinishWholeDayStudyIcon}
            handleClick={this.handleFullScreenRewardConfirmClick.bind(this)}
          />
        )}
        {this.state.currentPopupWindow === "showOfflineExamAllCorrect" && (
          <FullScreenCrystalReward
            point={this.props.all_correct_reward_offline_point}
            totalPoint={this.state.totalPoint}
            textUrl={HomeworkAllCorrectIcon}
            handleClick={this.handleFullScreenRewardConfirmClick.bind(this)}
          />
        )}
        <div className="check-wrong-header-border">
          <div className="check-wrong-header">
            请根据答案，选出错题，收入错题本。
          </div>
          {this.props.is_c4 ? (
            <div className="check-wrong-header-tips">
              注：列表算式的题型，写AxB=C，或BxA=C都算对
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="check-wrong-body">
          {this.state.wrongs &&
            this.state.wrongs.map((item, index) => {
              return (
                <WrongBook key={"a" + index}>
                  <img src={item.url} className="img-quiz"></img>
                  <div
                    className="quiz-wrong"
                    onClick={() => {
                      this.handleClick(index);
                    }}
                  >
                    <div
                      className="quiz-wrong-icon"
                      style={{ background: item.wrong ? "#FF6868" : "" }}
                    >
                      {item.wrong ? <img src={icon_correct}></img> : ""}
                    </div>
                    <div className="quiz-wrong-font">有错</div>
                  </div>
                </WrongBook>
              );
            })}
        </div>
        <div className="check-wrong-button">
          <div className="quiz-next-bottom quiz-next-bottom-back">
            <div
              className="quiz-next-bottom-flex"
              onClick={() => {
                window.location.href = this.props.details_url;
              }}
            >
              返回课程
            </div>
            <div className="buttom-icon">
              <span className="buttom-icon1"></span>
              <span className="buttom-icon2"></span>
            </div>
          </div>
          <div
            // onClick={this.props.handleClick}
            className="quiz-next-bottom"
            // style={{
            //   // fontSize: 12,
            //   background: this.props.answer
            //     ? ""
            //     : "linear-gradient(0deg, rgba(91,194,249,.6) 0%, rgba(170,226,255,.6)  100%)",
            // }}
          >
            <div
              className="quiz-next-bottom-flex"
              onClick={() => {
                if (this.props.quizzes_checked_url) {
                  request({
                    url: this.props.quizzes_checked_url,
                    headers: csrfHeaders,
                    method: "POST",
                    data: {
                      all_correct:
                        this.state.wrongIds.length === 0 &&
                        this.props.quizzes.quiz_info.length > 0,
                      study_package_activity_id: this.props
                        .study_package_activity_id,
                      page: this.props.page,
                    },
                  }).then((res) => {
                    this.processResponse(res)
                  });
                } else {
                  this.defaultFinishOfflineExamCheckOperation()
                }
              }}
            >
              {this.state.wrongIds.length > 0
                ? `错${this.state.wrongIds.length}题，去强化练习`
                : this.allCorrectComponent()}
              {/* {this.props.text && <span> {this.props.text}</span>} */}
            </div>
            <div className="buttom-icon">
              <span className="buttom-icon1"></span>
              <span className="buttom-icon2"></span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="add-fixed">
        <div className="center-style">
          <img src={no_data_icon}></img>
          <div className="no-data">
            请使用
            <span style={{ color: "#5AC2F9", fontWeight: 700 }}>
              “购买课程的微信号”
            </span>
            扫码，否则 做错的题目无法收入小朋友的错题本哦~
          </div>
        </div>
      </div>
    );
  }
}
