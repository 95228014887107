import React, { useEffect, useState } from "react";
import Crystal from "point_rewards/crystal";
import PropTypes from "prop-types";
import BabyPointIcon from "./BabyPointIcon";
import "./CrystalReward.scss";

const CrystalReward = ({
  point,
  newPointStyle,
  newCrystalStyle,
  newComponentStyle,
  totalPoint,
  showBabyPoint,
}) => {
  let pointStyle = {
    color: "#FFC000",
    fontSize: "28px",
    fontWeight: "bold",
    backgroundColor: "rgba(255,255,255,0.8)",
    borderRadius: "6px",
    padding: "0 4px",
  };
  let crystalStyle = {
    marginLeft: "15px",
    width: "74px",
    height: "74px",
  };
  const [originalTotalPoint, setOriginalTotalPoint] = useState(totalPoint - point)
  useEffect(() => {
    const audio = document.getElementById("reward-sound");
    setTimeout(() => {
      audio.play();
      setOriginalTotalPoint(totalPoint)
    }, 1000);
  }, []);
  pointStyle = Object.assign(pointStyle, newPointStyle);
  crystalStyle = Object.assign(crystalStyle, newCrystalStyle);
  
  return (
    <>
      {showBabyPoint && (
        <BabyPointIcon
          point={originalTotalPoint}
          newTextStyle={{ opacity: 0.8 }}
          newStyle={{ zIndex: 1001 }}
          customClassName="baby-point-icon-with-reward custom-small-icon"
        />
      )}
      <div className="crystal-reward-compoent" style={newComponentStyle}>
        <div style={pointStyle}>+{point}</div>
        <img src={Crystal} style={crystalStyle} />
        <audio
          src="https://upload.fireflybaby.cn/file/6a7c0bfca10f54cd3ce68eae7a49aff8.mp3"
          id="reward-sound"
        ></audio>
      </div>
    </>
  );
};

CrystalReward.propTypes = {
  point: PropTypes.number.isRequired,
  totalPoint: PropTypes.number.isRequired,
  newPointStyle: PropTypes.object,
  newCrystalStyle: PropTypes.object,
  newComponentStyle: PropTypes.object,
  showBabyPoint: PropTypes.bool,
};

export default CrystalReward;
