import React from "react";
import PropTypes from "prop-types";
import Crystal from "point_rewards/crystal";
import CustomSmallIcon from "../shared/CustomSmallIcon";

const BabyPointIcon = ({point, customStyle, customTextStyle, customClassName}) => {
  let style = {
    position: 'fixed',
    zIndex: 20,
  }
  style = Object.assign(style, customStyle)
  return (
    <CustomSmallIcon textContent={point} icon={Crystal} customStyle={style} customTextStyle={customTextStyle} customClassName={customClassName} />
  )
}

BabyPointIcon.propTypes = {
  point: PropTypes.number.isRequired,
  style: PropTypes.number,
}

export default BabyPointIcon